.activity-items-list th:not(:last-of-type) {
  width: 30%;
}

.activity-items-list th:last-of-type {
  width: 10%;
}

.activity-items-list-edit-modal {
  top: 50px !important;
  position: fixed;
}

.activity-inline-display {
  white-space: pre-line;
}