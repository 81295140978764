.communication-workflow-modal-contact-select {
  .select-contacts,
  .communication-workflow-modal-additional-options {
    label {
      display:block;

      input[type='checkbox'] {
        margin-right:.5rem;
      }
    }
  }
}

.communication-workflow-modal-contact-select-header,
.communication-workflow-modal-additional-options-header{
  font-size: 13px;
  font-weight: bold;
  padding: 0 0 3px 0;
}

.communication-workflow-modal-additional-options{
  margin-top: 10px;
}

.snippet-editor-modal {
  .modal-dialog {
    max-width: 800px;
  }
  .editor-info-text {
    padding: 1px 0 20px 0;
  }
  .snippet-attribute-help {
    padding-left: 0;
  }
}

.permission-set{
  display: inline-block;
  columns: 3;
  width: max-content;

  .permission{
    input[type=checkbox]{
      margin-right: 5px;
    }
  }
}