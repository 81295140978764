@import '~react-select/dist/react-select.css';

.oauth-config-domains {
  width: 500px;
}

.oauth-config-errors {
  margin-top: 5px;
}

.oauth-align-label {
  label {
    vertical-align: top;
  }
}
