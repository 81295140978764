.veracross-config-test {
  display: inline-block;
  margin-left: 1em;

  span {
    font-style: italic;
  }
}

.veracross-config-form {
  .base-setup-field-value {
    width: 50%;
  }
}
