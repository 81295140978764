.review-activities-none {
  text-align: center;
}

.review-activities-note-text {
  white-space: pre-wrap;
}

.review-activities-content {
  margin: 8px 0;
}
