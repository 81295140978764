@import '../../constants/variables';

.Placeholder-select:disabled {
  background-color: $gray5;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -628px 0
  }
  100%{
    background-position: 628px 0
  }
}

.placeholder-text {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: $gray37;
  background: linear-gradient(to right, $gray36 8%, $gray21 18%, $gray36 33%);
  position: relative;
}

.placeholder-text .background-masker {
  background: $white;
  position: absolute;
}

.placeholder-text .background-masker.content-line,
.placeholder-text .background-masker.content-end {
  top: 0;
  left: 0;
  right: 0;
}