@import '../shared/helpers/bootstrap_variables';

.nav.nav-pills.flat-pill {
  margin: 0;
  display: flex;
  background: url('../../images/shared_components/tab_bg.png') repeat-x bottom;

  li {
    margin:12px 0px;

    > a {
      line-height: 15px;
      padding: 0px 0 5px 0;
      margin: 8px 12px;
      color: $gray6;
    }

    > a:hover {
      background: none;
      color: $gray31;
    }

    > a:focus {
      background-color: transparent;
    }

    .sub-menu {
      line-height: 15px;
      padding: 8px 0;
      margin: 1px 0;
      padding-left: 12px;
      margin-right: 2px;
      display: block;

      a.active {
        color: $orange;
        font-weight: bold;
      }
    }
  }

  li:last-of-type {
    > a {
      margin: 8px 0px 8px 12px;
    }
  }

  li:first-of-type {
    > a {
      margin: 8px 12px 8px 0;
    }
  }

  li.active {
    background: url('../../images/shared_components/tab_caret_down.png') no-repeat bottom center;
    margin-bottom: 1px;

    a {
      background: none;
      color: $teal2;
      font-weight: bold;
    }
  }

  li.divider {
    &:before {
      color: $gray2;
      line-height: 30px;
    }
  }
}
