@import "../../../../../../stylesheets/shared/helpers/bootstrap_variables";
@import "../../../../constants/variables";

.review-controls-padding {
  height: 16px;

  &.open {
    height: 130px;
  }
}

.review-controls {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

$review-tab-height: 32px;
$review-tab-width: 180px;
$review-tab-border: 16px;

.review-controls-comments {
  width: $review-tab-width;
  height: $review-tab-height;
  font-size: 16px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
  text-align: center;
  text-transform: uppercase;
}

.review-controls-comments-tab {
  float: left;
  color: white;
  background-color: $primary;
  width: $review-tab-width - $review-tab-border * 2;
  height: $review-tab-height;
  cursor: pointer;

  .fa.fa-stack {
    display: inline-block;
    position: relative;
    top: -2px;
    font-size: 10px;

    i[class^="fa-chevron"] {
      color: $primary;
    }
  }
}

.review-controls-comments-tab-label {
  padding-top: 9px;
}

.review-controls-comments-left,
.review-controls-comments-right {
  float: left;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 $review-tab-border/2 $review-tab-height $review-tab-border/2;
  border-color: transparent transparent $primary transparent;
  position: relative;
  z-index: -10;
  -moz-transform: scale(0.9999); /* make edge smoother in FF */
}

.review-controls-comments-left {
  left: 8px;
}

.review-controls-comments-right {
  left: -8px;
}

.review-controls-comments-body {
  p {
    text-align: center;
    font-style: italic;
  }

  textarea {
    box-sizing: border-box;
    margin-bottom: 16px;
    width: 100%;
    height: 60px;
  }
}

.review-controls-inner {
  padding: 16px 24px;
  background-color: $gray5;
  border-top: 6px solid $primary;
}

.review-controls-page {
  color: $primary;
  font-size: 18px;
  line-height: 32px;
  text-align: center;

  .dropup {
    display: inline-block;
  }

  .dropup button {
    border: none;
  }

  .dropdown-menu {
    i {
      margin-right: 4px;
    }

    a {
      text-align: left;
    }
  }

  i.fa.fa-review-caret:before {
    content: "\f0d7";
  }

  .btn-group.dropup.open i.fa.fa-review-caret:before {
    content: "\f0d8";
  }
}

.review-controls-page-divider {
  margin-left: 8px;
  margin-right: 4px;
  color: $gray40;
}

.btn.review-controls-page-button {
  color: $blue15;
  background-color: $gray5;
  padding: 0 8px;
  font-size: 18px;
  line-height: 32px;

  &:after,
  &:before {
    content: none;
  }
}

.review-controls-page-name {
  color: $primary;
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
}

.review-controls-next {
  text-align: right;
}

.review-pages-menu-item {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: normal;

  svg {
    margin-right: 4px;
  }
}

.review-pages-menu-full-width {
  column-span: all;
}

.review-controls-comments.summary {
  width: 270px;

  .summary-tab {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
