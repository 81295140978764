@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';
@import '../../../../constants/variables';

/* Used to trigger IntersectionObserver that toggles "fixed" class */
.review-viewer-controls-sentinel {
  height: 1px;
}

.review-viewer-controls {
  padding: 0;
  text-align: right;

  .btn {
    margin-left: 4px;
  }
}

.fixed + .review-viewer-controls {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px 15px;
  z-index: 1100; /* in front of ck_fu */
  border-bottom: 2px solid $primary;
  background-color: $gray5;
}

.review-documents-not-available,
.review-documents-not-supported,
{
  text-align: center;

  button {
    margin-top: 8px;
  }
}

.review-documents-pdf-page {
  border: 1px solid $gray16;
  box-shadow: 1px 1px 4px $gray16;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

.review-documents-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

