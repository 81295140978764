@import '../../../../../../stylesheets/shared/helpers/variables';

.payment-profile-summary {
  .payment-profile-details {
    margin-bottom: 8px;
  }

  .autopay-header {
    font-weight: bold;
  }

  .autopay-notice {
    color: $green1;
  }

  margin-bottom: 18px;

  .btn {
    font-size:13px;
  }
}
