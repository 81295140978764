@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';

.billing-aging-report-date {
  margin: 15px 0 0 0;
}

.billing-aging-report-input{
  @extend .form-control;
  @extend .form-control-sm;

  margin: 0 0 0 5px;
}

.billing-aging-report-count {
  display: inline-block;
  margin: 0 5px 0 0;
}
