@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';

.sp-back-link {
  margin-top: 8px;
  margin-bottom: 16px;
}

.sp-form {
  @include media-breakpoint-down(lg) {
    .col-12, .col-6, .col-4, .col-3 {
        flex: 1 0 250px;
    }

    .col {
        flex: 1 0 50%;
    }
  }
}

.sp-intake-form {
  h2 {
    margin-bottom: 1rem;
    color: $orange9;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.sp-form-buttons {
  margin-top: 1.5em !important;

  button {
    width: 100%;
  }
}

.sp-intake-intro {
  margin-top: 2em;
  margin-bottom: 2em;
}

/*
  Validation errors
 */

$sp-error-color: $danger;

.sp-form-error-message {
  margin-left: 1ex;
  color: $sp-error-color;
}

.sp-form-field-has-error {

  input,
  .Select-control {
    border-color: $sp-error-color;
  }
}

/*
  Required field indicators. These reflect the rails "presence" validations on the underlying models.
 */

.sp-required-field {
  .form-label::after {
    content: " *";
    color: $sp-error-color;
  }
}
