@import '../../../constants/variables.scss';
@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';

.special-scrolling-behavior {
  max-height: calc(100vh - 240px);
  overflow-y: auto;
}

.wider-modal {
  .modal-dialog {
    max-width: 730px;
  }
}

.change-columns-modal {
  .results-section {
    display: flex;
    gap: 10px;

    .group-section, .field-section {
      flex: 1 1 50%;
    }

    .list-of-groups, .list-of-fields {
      border: $gray39 1px solid;
      padding: 5px;
    }

    .select-all-section {
      border-bottom: $gray39 1px solid;
    }

    .individual-cell {
      padding: 2px;
      &:hover {
        cursor: pointer;
      }
    }

    .custom-option-flex {
      display: flex;
      gap: 5px;
      width: 100%;
    }

    .custom-option-check-column {
      flex: 0 0 15px;
    }

    .custom-option-label-column {
      flex: 1 1 auto;
    }
  }

  .smaller-text-field {
    font-size: $font-size-base;
    padding: 0px 10px;
    line-height: 34px;
  }

  .field-selector-sticky {
    position: sticky;
    top: 0px;
  }

  .selected-columns-fill {
    flex: 1 1 33%;
  }

  .two-thirds-column {
    flex: 1 1 66%;
  }

  .group-button {
    &:hover {
      background: rgba($teal2, 0.08);
      cursor: pointer;
    }
  }

  .group-button.group-selected {
    background: rgba($teal2, 0.24);
  }

  .selected-fields-group {
    .selected-fields-value {
      background-color: rgba($teal2, 0.08);
      color: $teal2;
      border-radius: 2px;
      border: 1px solid rgba($teal2, 0.24);
      display: flex;
      line-height: 1.4;
      margin-top: 5px;
      vertical-align: top;
      width: 100%;
    }

    .selected-fields-label {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      cursor: pointer;
      padding: 2px 5px;
      display: inline-block;
      vertical-align: middle;
      flex: 1 1 auto;
    }

    .selected-fields-x {
      cursor: pointer;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      border-left: 1px solid rgba(0, 126, 255, 0.24);
      padding: 1px 5px;
      display: inline-block;
      vertical-align: middle;
      flex: 0 0 auto;

      &:hover {
        background-color: rgba($teal2, 0.08);
      }
    }
  }

  .space-above {
    margin-top: 15px;
  }

  .space-below {
    margin-bottom: 30px;
  }

  h2 {
    color: $teal2;
  }

  .col-selector-field-panel, .graph-options {
    display: flex;
    gap: 20px;
  }

  .graph-options {
    align-items: center;
    clear: left;

    .column-editor-graph-row-grouping {
      flex: 1 0 auto;
    }
    .graph-sample {
      flex: 0 0 auto;
    }
  }

  .required {
    color: red;
  }

  .results-display-styles {
    display: flex;
    gap: 15px;
  }

  .results-display-style-box {
    margin: 0;
    text-align: center;
    font-weight: bold;
  }

  .results-display-style-image {
    background: no-repeat scroll  center top transparent;
    background-size: 125px 93px;
    height: 93px;
    width: 125px;
    margin: 0;
    border: none;

    input {
      display: none;
    }
  }

  .graph-options {
    height: 175px;

    h4 {
      margin-top: 20px;
      float: left;
    }

    #graphing_fields {
      margin-top: 66px;
    }

    #row_grouping_fields {
      float: left;
      margin-top: 61px;
    }

    .graph-sample {
      float: right;
    }
  }

  .results-list {
    background-image: url('../../../../../images/admin_portal/search/search-type-list.png');
  }

  .results-list.active {
    background-image: url('../../../../../images/admin_portal/search/search-type-list-active.png');
  }

  .results-list-grouping {
    background-image: url('../../../../../images/admin_portal/search/search-type-list-grouping.png');
  }

  .results-list-grouping.active {
    background-image: url('../../../../../images/admin_portal/search/search-type-list-grouping-active.png');
  }

  .results-summary {
    background-image: url('../../../../../images/admin_portal/search/search-type-summary.png');
  }

  .results-summary.active {
    background-image: url('../../../../../images/admin_portal/search/search-type-summary-active.png');
  }

  .results-graph {
    background-image: url('../../../../../images/admin_portal/search/search-type-graph.png');
  }

  .results-graph.active {
    background-image: url('../../../../../images/admin_portal/search/search-type-graph-active.png');
  }
}
