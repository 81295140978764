@import "../../../constants/variables";
@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';

.ap-installment-list.table {
  margin-top: 24px;

  .sub {
    white-space: nowrap;
    overflow: hidden;
    color: $gray17;
  }

  td {
    border-top: none;
    padding: 0 8px;
  }

  tr:first-child td {
    padding-top: 8px;
  }

  tr:last-child td {
    padding-bottom: 8px;
  }

  tbody {
    border-top: 1px solid $gray12;
  }

  .ap-item-description {
    width: 30%;
  }

  .ap-item-total, .ap-item-total-balance, .ap-item-amount, .ap-item-balance {
    text-align: right;
    width: 120px;
  }

  .ap-installment-contact-highlight {
    color: $white;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ap-installment-toggle {
    width: 70px;
    cursor: pointer;
    white-space:nowrap;
  }

  .ap-installment-prepaid, .ap-installment-overdue, .first-post-current {
    td {
      border-top: 2px solid black;
    }
  }

  .ap-installment-prepaid {
    .ap-item-description, .ap-item-amount, .ap-installment-balance {
      color: green;
    }
  }

  .ap-installment-overdue {
    .ap-item-description, .ap-item-amount, .ap-installment-balance {
      color: $red0;
    }
  }

  .first-ap-installment {
    .ap-item-due-date, .ap-item-description, .ap-item-amount, .ap-installment-balance {
      font-weight: bold;
    }
  }

  .ap-item-amount, .ap-item-total {
    border-right: 1px solid black;
  }

  .ap-installment-balance {
    background-color: $gray4;
    white-space: nowrap;
  }
}
