.payment-plan-schedule-field{
  display: inline-block;
  padding: 1px 30px 0 0;

  >label {
    display:block;
  }
}


.payment-plan-schedule-input{
  width: 100px
}

.hide-schedule-field {
  display: none !important;
}

.payment-plan-schedule-description{
  display: inline-block;
  margin: 10px 0 10px 0;
}

.payment-plan-schedule-items{
  padding: 0 0 0 50px;
}

.plan-editor-payment-plan-fees {
  padding: 7px 0 0 0;
}

.plan-editor-payment-plan-fee {
  margin: 0 0 5px 0;
}

.plan-editor-payment-plan-fee input[type='checkbox'] {
  margin: 0 5px 0 0;
}

.payment-plan-schedules-actions {
  text-align: right;
}

.payment-plan-schedules-actions button {
  margin: 0 0 0 5px;
}

.installment-editor-warning {
  margin-bottom: 15px;
}
