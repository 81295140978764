@import '~react-select/dist/react-select.css';
@import '../../../constants/variables';

/* Let tooltips work in modals */
.tooltip {
  z-index: 50000;
}

.communication-plan-editor {
  /* Disable overflow set by modal.scss which causes problems for react-select */

  input[type='text'],
  select {
    width: 206px;
    padding: 4px 8px;
    border-radius: 3px;
    border: 1px solid $gray74;
    line-height: 21px;
  }

  .modal-body {
    overflow: visible;
  }

  .select-contacts {
    .checkbox {
      display: block;
    }
  }
}
