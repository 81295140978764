@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-sortable-list-column-field-name {
  @include make-col(8);
}

.review-sortable-list-column-option {
  @include make-col(6);
  
  @include media-breakpoint-down(lg) {
    @include make-col(8);
  }

  text-align: center;
}

.review-sortable-list-column-actions {
  @include make-col-offset(6);
  @include make-col(3);
  
  @include media-breakpoint-down(lg) {
    @include make-col-offset(4);
  }

  text-align: center;
}