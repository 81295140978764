@import '../../../constants/variables';

.billing-calendar {
  .billing-calendar-filters{
    margin-top:10px;
    margin-bottom:10px;

    b{
      margin-left: 10px;
    }
  }

  .contact-list-react {
    margin-top: 20px;
  }

  .billing-calendar-actions{
    float: right;
    margin-bottom: 10px;
  }

  thead {
    tr {
      th {
        width: 1%;
        border-bottom: none;
        white-space: nowrap;
      }
    }
  }

  tbody{
    .billing-calendar-total-col {
      font-weight: bold;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
    }

    .billing-calendar-subtotal-col {
      font-weight: bold;
      border-left: 1px solid $black;
    }

    .billing-calendar-paid-col {
      font-weight: bold;
      border-left: 1px solid $black;
    }

    tr:nth-child(odd) {
      background-color: $gray10;
    }
  }

  tr {
    td {
      border-bottom: none;
    }
  }

  tr:last-of-type {
    td {
      border-bottom: 1px solid black;
    }
  }

  td {
    width: 1%;
    white-space: nowrap;
  }
  .billing-calendar-extra-blank {
    width: 100%;
    visibility: hidden;
  }
  td.contact-list-react-name{
    border-right: 1px solid $black;
  }
  td.payment-plan-name {
    border-right: 1px solid $black;
  }

  .contact-list-react-rows {
    .billing-calendar-row-contact {
      .contact-list-react-name {
        border-right: none;
      }
    }
  }

  .billing-calendar-day-col {
    color: $gray1;

    .negative-number {
      color: inherit;
    }
  }

  .billing-calendar-row-contact-category {
    td {
      border-top: none;
    }
  }

  .billing-calendar-row-contact, .billing-calendar-row-total {
    td, th {
      font-weight: bold;
      border-top: 2px solid $black;
      border-bottom: 2px solid $black;
      background-color: $blue5;
    }
  }

  .billing-calendar-row-category-group {
    td, th {
      border-top: 1px solid $black;
    }

    .contact-list-react-name {
      font-weight: bold;
    }
  }

  .billing-calendar-today-line {
    border-left: 1px dashed $gray2;
  }

}
