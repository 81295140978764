@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';

.billing-category-groups {
  .link-button {
    text-decoration: none;
    .fa {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  &.reordering {
    cursor: move;
  }

  .group-row {
    display: flex;
    flex-direction: row;
  }

  .group-col {
    flex: auto;
    padding: 8px;

    &.group-sort {
      text-align: center;
      flex-grow: 0;
      width: 24px;
    }

    &.group-name {
      flex-grow: 3;
    }

    &.group-actions {
      text-align: right;
      flex-grow: 2;

      .link-button {
        margin: 0 6px;
      }
    }
  }

  .drag-handle {
    cursor: move;
  }

  .group-header {
    font-weight: bold;
    border-bottom: 2px solid $gray3;
  }

  .group-collapsed {
    border-bottom: 1px solid $gray3;

    &:nth-child(2n+1) {
      background-color: $gray5;
    }

    &.sortable-dragging {
       background-color: $white;
       border-top: 1px solid $gray3;
       border-bottom: 1px solid $gray3;
     }
  }

  .group-expanded {
    background-color: $white;
    border-bottom: 2px solid $gray3;

    .group-name {
      font-weight: bold;
      color: $primary;
    }

    .detail-header {
      border-bottom: 1px dashed $gray3;
    }

    .detail-panel {
      padding: 8px;
      margin-left: 33px;

      h3 {
        color: $orange !important;
      }
    }
  }

  .category-inactive {
    * {
      color: $gray2 !important;
    }
  }

  .create-group-form {
    display: flex;
    align-items: baseline;

    input {
      margin-right: 20px;

      &.edit-new-group-name,
      &.edit-new-group-account {
        min-width:200px;
      }
    }
  }

  .error-inline {
    padding-left: 5px;
  }

  .subcategory-actions {
    .link-button {
      margin-left: 6px;
    }
  }

  .subcategories-table {
    input {
      width: 100%;
      margin-top: -10px;
      margin-left: -7px;
      margin-bottom: -7px;
    }

    .link-button {
      text-align: left;
    }

    td {
      .subcategory-actions {
        display: flex;
        justify-content: flex-end;

        .action {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .details-group-actions {
    padding: 12px;
    margin-bottom: 12px;
    min-height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .category-group-options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .category-group-option {
      color: black;
      margin-bottom: 6px;
    }
  }
}

.inactive-categories {
  margin-top: 40px;

  h2 {
    color: $gray1 !important;
  }
}
