.review-pdf-viewer {
  .page {
    direction: ltr;
    width: 816px;
    height: 1056px;
    margin: var(--page-margin);
    position: relative;
    overflow: visible;
    border: var(--page-border);
    border-image: var(--page-border-image);
    background-clip: content-box;
    background-color: rgba(255, 255, 255, 1);
  }
}