@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';
@import '../../../constants/variables.scss';

.sp-person-name {
  font-weight: bold;
  font-size: 14px;
}

.sp-person-icon, .sp-person-icon-small {
  border-radius: 50%;
  color: $gray6;
  background-color: $gray4;
  text-align: center;
}

.sp-person-icon {
  height: 60px;
  width: 60px;
}

.sp-person-icon-small {
  height: 40px;
  width: 40px;
}

.sp-person {
  text-align: center;
  flex: 0 0 auto;
  display: block;
}

.sp-person-same-line {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}
