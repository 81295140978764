@import '../../../constants/variables.scss';

select.review-decision-cell__select {
  margin: 0;
  padding: 4px 6px;
  font-size: 13px;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    border-color: $gray74;
  }
}

.review-decision-cell__field {
  padding: 0;
  border: none;
  border-radius: 0;
  border-bottom: 1px dotted $gray2;
  color: $black1;

  &:focus,
  &:hover {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px dotted $gray2;
    outline: none;
    text-decoration: none;
    color: $black1;
  }

  &.empty {
    font-style: italic;
    color: $red2;
    text-decoration: none;
  }
}