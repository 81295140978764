@import '../../../constants/variables';

.billing-day-confirm-modal-options {
  list-style: none;

  input[type="radio"] {
    margin-top: -1px;
    vertical-align: middle;
  }

  label {
    display: inline;
    margin-left: 8px;
  }
}

.billing-config-autopay-changelog {
  display: inline-block;
  margin: 0 0 0 10px;
  vertical-align: bottom;
}

.billing-config-autopay-disabled {
  color: $gray2;
}

.billing-general-settings .base-setup-field-value .react-datepicker-wrapper {
  width: 100%;
}

.special_character_flag {
  font-size: 0.875em;
  color: $red0;
  margin-top: 4px;
  margin-bottom: 0;
}
