#financial-split-modal {
  .financial-split-modal-header {
    font-size: 16px;
    display: inline-block;
    margin: 0 0 10px 0;
  }
  
  .financial-split-modal-contacts {
    display: block;
    margin: 15px 0 10px 0;
  }
  
  .financial-split-modal-total {
    font-weight: bold;
    display: block;
    margin: 15px 0 10px 0;
  }
  
  .financial-split-year-select {
    display: inline-block;
    width: 125px;
    margin: 0 0 0 15px;
    font-size: 16px;
  }
  
  .financial-split-modal-percent {
    text-align: right;
    width: 88px;
  
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}