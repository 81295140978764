@import '../../../constants/variables';

.contact-list-react {
  .email-opens-failed {
    color: $red0;
  }
}

.email-opens-date-filter{
  padding: 15px 0 0 0;
}

.email-opens-failed-emails {
  padding-top: 10px;
}

.email-open-rates {
  .react-pagination {
    padding-bottom: 5px;
  }
}

.email-open-rates-percent {
  white-space: nowrap;
}


