@import '../../constants/variables';

.manage-shared-portal-group-modal-schools-gender-options,
.manage-shared-portal-group-modal-schools-ethnicity-options {
  margin-bottom: 5px;
}

.manage-shared-portal-group-modal-schools-custom-background,
.manage-shared-portal-group-modal-schools-custom-logo {
  height: 100px;
  max-width: 300px;
  margin-bottom: 10px;
}

.branded-portal-fields {
  padding: 1rem;
  border: 2px solid $gray17;
  border-radius: 5px;

  .branded-portal-fields-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: $black2;
  }

  .text-muted {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
}
