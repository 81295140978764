.hover-menu-area {
  height: 100%;
  position: relative;

  .hover-menu-button {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .hover-menu-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: -20px;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    &.show {
      display: block;
    }
  }

  .hover-menu-item {
    display: block;
    padding: 20px;
    clear: both;
    white-space: nowrap;
    border: 0;
  }
}

.sp-layout-main {
  padding-top: 24px;
  padding-bottom: 24px;
}
