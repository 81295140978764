.add-account-access-selector {
  width: 70%;
  display: inline-block;
}

.add-account-access-buttons {
  width: 30%;
  display: inline-block;
  text-align: right;
}
