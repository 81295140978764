@import '../../../../../stylesheets/helpers/variables';

.delayed-jobs-total-counts {
  text-align: right;
}

.delayed-job-status-failed {
  color: $red0;
}

.delayed-job-status-pending {
  color: $grey3;
}

.delayed-job-status-in_progress {
  color: $yellow0;
}

.delayed-job-button-icon {
  margin: 0 5px 0 0;
}

.delayed-jobs-header {
  display: inline-block;
}

.delayed-jobs-header-refresh {
  margin: -10px 0 0 10px;
}

.delayed-jobs-table-header {
  cursor: pointer;
}

.delayed-job-handler {
  font-family: "Courier New", Courier, monospace;
  overflow-x: scroll;
}

.delayed-job-details {
  padding: 8px;
  border: 1px solid $grey3;
  border-radius: 4px;
}

.delayed-job-details-label {
  font-weight: bold;
  padding: 8px;
}

.delayed-job:hover td {
  background: $grey5;
}

.delayed-job-last-error {
  font-family: "Courier New", Courier, monospace;
  overflow-x: scroll;
  white-space: nowrap;
}

.delayed-jobs-priority-subrow {
    color: $gray14;
}

.delayed-jobs-priority-table {
  margin-top: 0px;
}

.delayed-jobs-failed-count {
  margin-bottom: 10px;
}

.delayed-jobs-expander-column {
  width: 30px;
}
