.counter-signature-label{
  padding: 3px 0 6px 8px;
  font-weight: bold;
}

.counter-signature-explanation{
  padding-bottom: 4px;
}

.counter-signature-warning{
  margin: 8px 10px 3px 0px;
  float: left;
  font-size: 24px;
}