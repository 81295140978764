#billing-import-job__modal {
  
  .billing-import-job__form {
    margin-left: 70px;
  }

  .billing-import-job__form-group {
    & > label {
      flex: 0 0 auto;
      text-align: end;
      margin-right: 5px;
    }

    .form-check input:first-of-type {
      margin-top: 10px;
    }
  }
}