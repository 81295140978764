.sp-dashboard-school-logo {
    width: 100px;
    height: 100px;
}

.sp-config-grades-table {
  margin-bottom: 15px;
  td {
    padding: 0px 15px 0px 0px;
  }

  .sp-config-portal-grade {
    width: 25%;
  }
}
