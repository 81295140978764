@import "../../constants/variables.scss";
@import "../../../../stylesheets/helpers/variables";

.lead-source-filters {
  margin-top: 24px;
}

.lead-source-report {
  margin-top: 24px;

  th, td {
    text-align: center;
  }

  th {
    border-bottom-width: 2px;
  }

  th.col-role, th.col-stage {
    color: $white;
    background-color: $applicant-objects;
  }

  th:not(:first-child),
  td:not(:first-child) {
    border-left: 1px solid $gray39;
  }

  tfoot {
    td {
      border-top-width: 2px;
      border-bottom: none;
    }

    td:first-child {
      font-weight: bold;
    }
  }
}
