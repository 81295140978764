@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';
@import '../../../constants/variables.scss';

.sp-dashboard-students-applying {
  display: flex;
  gap: 10px;
  align-items: baseline;
  color: $blue0;
  padding-bottom: 15px;
}

.sp-dashboard-school-row {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  align-items: flex-start;
  @include media-breakpoint-down(md) {
    justify-content: space-evenly;
    align-items: center;
  }
}

.sp-dashboard-name-grade-column {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 420px;
    justify-content: space-between;
  }
}

.sp-dashboard-add-schools-button {
  width: 150px;
  margin: 10px;
}

.sp-dashboard-schools {
  .sp-dashboard-icons-column,
  .sp-dashboard-logo-column {
    padding-top: 0px;
  }

  .sp-dashboard-icons-column {
    display: flex;
    gap: 20px;
    justify-content: start;
    flex-wrap: wrap;
  }
}

.sp-dashboard-logo-column {
  width: 100px;
  @include media-breakpoint-down(md) {
    order: -2;
  }
}

.sp-dashboard-school-logo {
  width: 100px;
  height: 100px;
}

.sp-dashboard-school-name {
  font-weight: bold;
  font-size: 18px;
}

.sp-dashboard-block {
  display: block
}

.sp-dashboard-minor-text {
  color: $gray2;
}

.sp-dashboard-grade-value {
  font-weight: bold;
  font-size: 14px;
}

.sp-dashboard-checklist-section {
  flex: 1 1 auto;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.sp-dashboard-checklist-column {
  text-align: center;
  @include media-breakpoint-up(md) {
    width: 200px;
  }
  @include media-breakpoint-down(md) {
    order: -2;
  }
}

.sp-dashboard-checklist-button {
  width: 100%;
  margin-bottom: 5px;
}

.sp-dashboard-checklist-complete {
  color: $green2;
}

.sp-dashboard-checklist-incomplete {
  color: $red2;
}

.sp-dashboard-icons-column {}

.sp-dashboard-student-icon {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 13%;
}
