@import '../../../constants/variables';

.view-mms-modal {
  top: 50px !important;
}

.view-mms-field {
  margin: 0 0 10px 0;
  overflow: hidden;
}

.view-mms-label {
  font-weight: bold;
  width: 20%;
  float: left;
  text-align: right;
  box-sizing: border-box;
  padding: 0 10px 0 0;
}

.view-mms-value {
  width: 80%;
  float: left;
}

.view-mms-body {
  font-family: sans-serif;
  min-height: 300px;
  border: 1px dashed $gray3;
  margin: 15px 0 0 0;
}