.tag-list-add {
  .fa-plus-circle {
    margin-right: 4px;
  }
}

.tag-list-table {
    button {
      padding: 0 4px;
    }
}