@import '../../../constants/variables';

.ledger-item-filter{
  padding: 0 3px 7px 0;
  display: inline-block;

  cursor: pointer;
}

.ledger-item-filter-submit{
  margin: 15px 0 0 0;
  display: block;
  float: left;
}

.ledger-title{
  padding: 0 0 20px 0;
}


.ledger-currency{
  text-align: right;
}
