@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';
@import '~bootstrap/scss/mixins';

.review-contact-list__navigation-row {
  @include make-row();
  margin: 15px 0;
  justify-content: space-between;

  .triangle-breadcrumb .btn {
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active,
    &:visited,
    &:target {
      box-shadow: none;
    }
  }

  .pagination a {
    &:focus,
    &:focus-visible,
    &:focus-within {
      box-shadow: none;
    }
  }
}

.review-contact-list__navigation {
  @include make-col(12);
}

.review-contact-list__pagination {
  @include make-col(12);

  text-align: end;
}