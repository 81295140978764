.field-editor{
  input, textarea{
    padding: 3px 1px 2px 5px;
    font-size: 0.875rem;
    border-radius: 3px;
    width: auto;
    border-color: lightgrey;
  }

  label{
    font-size: 18px;
    padding-bottom: 10px;
  }

  .field-editor-section{
    padding: 0 0 20px 0;
  }

  .sa-froala-prompt .fr-wrapper {
    min-height: 30px;
  }

  .sa-froala-explanation .fr-wrapper {
    min-height: 90px;
  }
}

