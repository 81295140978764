@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '../../../constants/variables';

// Flex box for the school explorer
.sp-school-explorer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  height: 100%;
}

.sp-school-explorer-search-container {
  flex: 2 0 335px;
  display: flex;
  flex-direction: column;
  max-height: 800px;
  max-width: 400px;
}

.sp-school-explorer-search-container.sp-active-school {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.sp-back-to-list-button {
  display: block;
  @include media-breakpoint-up(md) {
    display: none;
  }
  position: fixed;
  bottom: 15px;
  align-self: center;
  box-shadow: 0px 0px 10px $gray16;
}


.sp-school-explorer-search-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.sp-school-explorer-search-filters-item {
  width: 49%;
  min-width: 175px;
}

.sp-explorer-schools-table {
  overflow: auto;
  margin-top: 20px;
}

.sp-school-explorer-search-results {
  width: 100%;

  td {
    padding: 5px;
  }
}

.sp-school-explorer-search-results-school.active {
  background: linear-gradient(to right, $white 0%, rgb(241, 241, 241) 100%);

  .sp-school-explorer-search-results-school-grade {
    border-right-color: $red;
  }
}

.sp-school-explorer-search-results-school-logo-container {
  width: 50px;
}

.sp-school-explorer-search-results-school-logo {
  min-width: 50px;
  width: 50px;
  height: 50px;
}

.sp-school-explorer-search-results-school-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.sp-school-explorer-search-results-school-name {
  font-weight: bold;
  font-size: larger;
}

.sp-school-explorer-search-results-school-address {
  font-size: small;
}

.sp-school-explorer-search-results-school-grade {
  border-right-width: 5px;
  border-right-style: solid;
  border-right-color: $white;
}

.sp-school-explorer-search-results-school-grade-label {
  font-size: small;
  white-space: nowrap;
}

.sp-school-explorer-search-results-school-grade-value {
  font-weight: bold;
}

.sp-school-explorer-school-details-container {
  display: flex;
  flex-direction: column;
  flex: 5 5 500px;
}

.sp-school-explorer-school-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }
}

.sp-school-explorer-school-details-header-logo {
  flex: 0 0 auto;
  min-width: 100px;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.sp-school-explorer-school-details-body {
  flex: 1 0 300px;
  @include media-breakpoint-down(lg) {
    order: 3;
  }
}

.sp-school-explorer-school-details-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 150px;
  width: 150px;
}

.sp-school-explorer-school-details-actions-button-apply {
  margin-bottom: 10px;
}

.sp-school-explorer-school-details-programs {
  margin-top: 10px;
}

.sp-school-explorer-badge-list .badge:not(:first-child) {
  margin-left: 0.5em;
}

.shared-portal-page-widget {
  padding-top: 20px;
  overflow: auto;
  max-height: 600px;
}

.shared-portal-page-widget img {
  max-width: 100%;
}

.shared-portal-page-widget .fr-emoticon {
  padding-right: 13px;
}
