.api-overview {
  font-size: 1.0rem;

  .spacing {
    padding-top: 10px;
  }

  h1 {
    color: black;
    font-size: 2rem;
    padding-bottom: 2px;
  }

  h2 {
    color: black;
    font-size: 1.8rem;
    padding-bottom: 3px;
    border-bottom: 1px solid lightgrey;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  div {
    margin-top: 14px;
  }

  p {
    line-height: 1.5rem
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ol {
    li {
      padding-left: 6px;
      padding-bottom: 3px;
    }
  }

  code {
    background-color: #e8e8e8bf;
    padding: 2px;
    border-radius: 3px;
    color: black;
  }

  thead {
    border-bottom: 1px solid lightgrey;
  }
}