.data-retention-container {
  display: flex;
  flex-direction: column;
}

.data-retention-row{
  display: flex;

  flex-direction: row;
  padding: 5px 0 0 0;
}

.confirm-submit-row{
  display: flex;

  flex-direction: row;
  padding: 10px 0 0 0;
}

.confirm-submit-input{
  margin-left: 10px;
}

h2.data-retention-header{
  margin: 10px 0 10px 0
}

h1.data-retention-title{
  padding: 10px 0 12px 0;
}

label.data-retention-label{
  padding: 0 0 2px 6px;
  margin: 1px 0 0 0;
}

