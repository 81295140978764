.communication-workflow-modal {
  &.communication-workflow-modal-form {
    .modal-dialog {
      max-width: 800px;
    }
  }

  &.communication-workflow-modal-email-preview {
    .modal-dialog {
      max-width: 910px;
    }
  }
}

