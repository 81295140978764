// The modal controls for the modal itself are in form_import.scss
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '../../../constants/variables';

.form-import-match-title {
  width: 87%;
  text-align: left;
  padding: 5px 0 5px 20px;
  height: 30px;
  border-radius: 6px;
  margin: 0 0 4px 0;
}

.form-import-subheader{
  text-align: left;
  padding-top: 10px;
}

.form-import-strong {
  color: $white;
  background-color: $green6;
}

.form-import-medium {
  color: $white;
  background-color: $yellow0;
}

.form-import-weak {
  color: $white;
  background-color: $red1
}

.form-import-paragraph {
  padding: 10px 0 10px 15px;
}

.form-match {
  @include make-col-auto();
  padding-right:0;
}

.form-import-related-contacts {
  height: 150px;
  overflow-y: auto;
  overflow-x: auto;
}

.match-display {
  border-width: 2px;
  border-style: solid;
  border-color: $gray4;
  border-radius: 10px;
  margin-right: 5px;
  padding: 10px;

}

.match-background {
  background-color: $gray5;
}

.form-import-match {
  padding: 2px;
  margin: 4px 0 0 0;
  height: 26px;
  border-bottom: rgba(114, 114, 114, 0.18) 1px;
  border-bottom-style: solid;

}

.form-import-related-contact {
  padding: 6px 0 4px 0;
}

.form-import-match-button {
  border-top: rgba(114, 114, 114, 0.18) 1px;
  border-top-style: solid;
  padding: 12px 2px 32px 2px;
  height: 23px;

}

.form-import-helper-text{
  position: relative;
  float: left;
}

.match-label {
  margin: 7px;
  float: left;
  position: relative;
  bottom: 10px;
  right: 1px;

   input{ /* HIDE RADIO */
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
  }
  input + i{ /* IMAGE STYLES */
    cursor:pointer;
    border:2px solid transparent;
  }
  input:checked + img{ /* (RADIO CHECKED) IMAGE STYLES */
    border:2px solid $red10;
  }
}

.match-year-display {
  float: left;

}

.confirmation-text {
  padding-left: 20px;
  padding-right: 20px;
}

.appointment-container {
  display: flex;
  gap: 20px;
}

.multiple-appointments, .new-appointment {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  flex: 1;
  min-width: 300px;

  p {
    font-style: italic;
    margin-bottom: 5px;
  }

  .schedule-group {
    h3 {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.multiple-appointments h2 {
  color: $teal2;
}

.schedule-group {
  h3 {
    font-weight: bold;
    color: $black;
  }

  .appointment-item p {
    margin-bottom: 0px;
  }
}

.parent-contact-info {
  h3 {
    font-weight: bold;
    color: $black;
  }

  p {
    margin-bottom: 0px;
    display: flex;
    align-items: center;

    .contact-description {
      color: $black;
      margin-right: .5rem;
      min-width: 40px;
    }
  }
}