@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.manage-readers-modal-body {
  // This fixes an issue where the select dropdown is hidden under the modal footer
  // caused by layouts/modal.scss Modal Overflow .modal-body rules
  overflow: visible;
}

.manage-readers-modal__form {
  @include make-container();

  margin-bottom: 15px;
}

.manage-readers-modal__form-group {
  @include make-row();
}

.manage-readers-modal__control,
.manage-readers-modal__select {
  @include make-col-ready();
  @include make-col(16);
}

.manage-readers-modal__label {
  @include make-col-ready();
  @include make-col(8);

  text-align: end;
}
