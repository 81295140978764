@import '../../../constants/variables';
@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';

.appointment-list-wrapper {
  @include make-row();
}

.appointment-list-sidebar {
  @include make-col-ready();
  @include make-col(6);
}

.appointment-list {
  @include make-col-ready();
  @include make-col(18);
}

.appointment-list-section {
  margin: 0 0 25px 0;
}

.appointment-list-section-header {
  border-bottom: 1px solid;
  margin: 10px 0 5px 0;
  font-weight: bold;
  padding: 5px;
}

.appointment-list-section-header-title {
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 5px 0 0;
  display: inline-block;
}

.appointment-list-appointment {
  overflow: hidden;
  padding: 5px 5px 15px 5px;
  margin: 0 0 0 0;
  position: relative;

  &:hover {
    background: $gray5;

    .appointment-list-appointment-cancel {
      display: block;
    }
  }
}

.appointment-list-appointment-time {
  float: left;
  width: 25%;
}

.appointment-list-appointment-info {
  float: left;
  padding: 0 0 0 50px;
}

.appointment-list-appointment-student, .appointment-list-appointment-contact {
  color: $gray1;
}

.appointment-list-appointment-time-of-day {
  font-weight: bold;
}

.appointment-list-appointment-calendar-name {
  font-weight: bold;
}

.appointment-list-empty {
  margin: 0 0 0 5px;
  font-style: italic;
}

.appointment-list-section-today > .appointment-list-section-header,
.appointment-list-section-scheduled-today .appointment-list-section-header {
  color: $yellow0;
}

.appointment-list-section-tomorrow > .appointment-list-section-header,
.appointment-list-section-scheduled-yesterday > .appointment-list-section-header {
  color: $orange;
}

.appointment-list-section-distant > .appointment-list-section-header,
.appointment-list-section-scheduled-distant > .appointment-list-section-header {
  color: $gray1;
}

.appointment-list-sidebar-calendar {
  margin: 0 0 15px 0;
}

.appointment-list-sidebar-calendar-box {
  &:hover {
    cursor: pointer;
  }
}

.appointment-list-sidebar-calendar .fa-stop {
  margin: 0 3px 0 0;
}

.appointment-list-sidebar-calendar-title {
  display: flex;

  a {
    color: inherit;

    &:hover, &:active, &:focus {
      color: inherit;
    }
  }

  .fa-stop {
    color: $gray1;
  }

  .fa-caret-right, .fa-caret-down {
    &:hover {
      cursor: pointer;
    }
  }
}

.appointment-list-filter-header {
  color: $gray1;
  font-style: italic;
  margin: 0 0 5px 0;
}

.appointment-list-sidebar-schedules {
  margin: 0 0 0 25px;
}

.appointment-list-sidebar-schedule {
  margin: 5px 0 0 0;
  display: block;
}

.appointment-list-range-filter {
  margin: 0 0 15px 0;
}

.appointment-list-range-filter .btn:focus {
  outline: none;
}

.appointment-list-appointment-cancel {
  top: 5px;
  right: 5px;
  position: absolute;
}

.appointment-cancel-modal-contact-info {
  overflow: hidden;
  margin: 10px 0 5px 0;
}

.appointment-cancel-modal-label {
  font-weight: bold;
  width: 25%;
  text-align: right;
  float: left;
  margin: 0 15px 0 0;
}

.appointment-list-appointment-cancel {
  display: none;
}
