.reindex .Select {
  width: 25%;
  margin: 0 0 0 20px;
}

.reindex input[type='radio'] {
  margin-top: 2px;
}

.reindex-disabled {
  text-decoration: line-through;
}

.reindex-unavailable {
  margin: 0 0 0 5px;
}