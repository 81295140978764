@import '../../../../stylesheets/shared/helpers/bootstrap_variables';

.reminder {
  background: $gray5;
  padding: 10px;
  border-radius: 3px;
  margin: 0 0 15px 0;
  display: flex;
  flex-flow: row wrap;
  position: relative;

  &:hover {
    .reminder-edit,
    .reminder-delete {
      display: block;
    }
  }
}

.reminder-content {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  padding: 0 0 0 5px;
}

.reminder-header {
  flex: 1;
}

.reminder-check {
  transition: all .3s ease;
}

.reminder-complete {
  border: 0;
  background: transparent;
  font-size: 18px;
  width: 40px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, .05);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px 0 10px;
  color: rgba(0, 0, 0, .15);
  margin: -10px 5px -10px -10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  &:hover {
    background: rgba(0, 0, 0, .025);
    color: rgba(0, 0, 0, .35);
    cursor: pointer;

    .reminder-check {
      transform: scale(1.3);
    }
  }

  &:active {
    background: rgba(0, 0, 0, .35);
    color: $white;
  }

  &:focus {
    outline: 0;
  }
}

.reminder-edit {
  right: 14px;
  display: none;
  color: $primary;
}

.reminder-delete {
  right: -10px;
  display: none;
  color: $red0;
}

.reminder-save {
  right: 14px;
  color: $green1;
}

.reminder-revert {
  right: -13px;
  color: $gray32;
}

.reminder-action {
  position: absolute;
  cursor: pointer;
  font-size: 20px;
  top: -15px;
  border: none;
  background: transparent;
  transition: all .3s ease;
  padding: 0;

  &:focus {
    outline: 0;
  }

  &:hover {
    transform: scale(1.4);
  }
}

.reminder-action-icon {
  color: $white;
  font-size: 10px
}

.reminder-date {
  text-align: right;
}

.reminder-body {
  margin: 5px 0 0 0;
  width: 100%;

  > textarea {
    width: 97%;
    min-height: 55px;
  }
}

.reminder-body-empty {
  color: $gray32;
}

.reminder-footer {
  margin: 5px 0 0 0;
  padding: 5px 0 0 0;
  width: 100%;
  order: 1;
}

.reminder-inline {
  color: $primary;
  border: 0;
  background: transparent;
  padding: 0;
  vertical-align: baseline;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}

span.reminder-user-selector {
  color: $primary;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
    cursor: pointer;
  }
}

.reminder-user-deleted {
  color: $red0;
}
