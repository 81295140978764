@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-settings-menu {
  flex-direction: column;
  padding-left: 32px;
  margin: 0;

  .nav-item {
    margin: 0 0 10px 0;
  }

  .nav-link {
    padding: 5px;
    border: none;
    display: inline;

    &:hover, &:focus {
      text-decoration: none;
      box-shadow: none;
    }

    &.active {
      border-radius: 3px;
      color: $white;
      background: $gray1;
    }
  }
}