ul.tag-list {
  display: inline;
  padding-left: 0;
  margin-bottom: 0;

  li {
    display: inline;
    list-style: none;
  }

  li:not(:first-child) {
    margin-left: 6px;
  }
}

span.tag-list-name {
  background-color: #f3f3f3 !important;
}
