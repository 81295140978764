@import '../../../constants/variables';
@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';

.payment-plan-fee {
  @include make-row();
  margin-bottom: .75rem;
}

.payment-plan-fee-name, .payment-plan-fee-amount {
  @include make-col-ready();
  font-weight: bold;
}

.payment-plan-fee-name {
  @include make-col(16);
}

.payment-plan-fee-amount {
  @include make-col(8);
  text-align: right;

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}

.payment-plan-fee-description {
  @include make-col-ready();
  @include make-col(16);
  color: $gray2;
}

