.flash-message {
  min-width: 40%;
  max-width: 55%;
  margin: 0 auto 10px auto;
  display: table;
  text-align: center;
}

.flash-container {
  position: fixed;
  top: 25px;
  z-index: 10000;
  width: 100%;
  left: 0;
}
