@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.bulk-print-modal__form-group {
  @include make-row();

  margin-bottom: 15px;
}

.bulk-print-modal__label {
  @include make-col-ready();
  @include make-col(6);

  padding-top: 0;
  text-align: right;
}

.bulk-print-modal__control {
  @include make-col-ready();
  @include make-col(18);
}

.bulk-print-modal__pages-select-select {
  @include make-col(22);
}
