.customer-note-form{
  margin: 0 0 50px 0
}

.customer-note-textarea{
  display: block;
  height: 100px;
  width: 100%;
}

.customer-note-btn{
  float: right;
  position: relative;
  right: 0;
  top:10px;
}

.customer-note-last-edited{
  float: right;
  font-style: italic;
  position: relative;
  top: 47px;
  right: -85px;
}

