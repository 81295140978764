.review-scores-next-candidate {
  margin-top: 18px;
}

.review-scores-back-to-reviews {
  margin-top: 12px;
}

.summary-comments {
  width: 100%;
  height: 150px;
}
