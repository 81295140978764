.gender-display {
  position: relative;
  right: 10px;
  padding: 6px 0 0 0;
  bottom: 8px;

  .right {
    display: inline-flex;
    flex-direction: row;
  }
}

.pronoun-label{
  position: relative;
  right: 139px;
  top: 40px;
  text-align: right;
  width: 20.83333333%;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  font-weight: 700;
}

.gender-label {
  padding: 0 0 4px 6px;
  font-weight: normal;
  vertical-align: middle;
  margin-bottom: 0;
  display: inline-block;
  max-width: 100%;

}


.gender-option-spacing{
  display: inline-block;
  padding: 8px;

  .input-group-text {
    margin-left: 2px;
  }
}

.gender-other-spacing{
  float: left;
  position: relative;
  right: 5px;
}
.gender-other{
  bottom: 25px;
  position: relative;
}

.gender-radio-other {
  position:relative;
  left: 5px;
}
.sa-form-trigger {
  padding: 0 0 0 10px
}

.gender-txt {
  top: 12px;
  position: relative;
}

@media (max-width: 992px) {
  .pronoun-label {
    position: static;
    width: 100%;
    text-align: left;
    right: 0;
    top: 0;
    padding-top: 0.375rem;
  }
}
