@import '../../../constants/variables';

.select-picker {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.select-picker-target {
  border: 2px solid $gray13;
  border-radius: 5px;
}

.select-picker-target button {
  background: none;
  outline: 0;
  border: 0;

  &.non-interactive {
    cursor: default;
  }

  &:focus {
    outline: 0;
  }

  .fa-caret-down {
    margin: 0  0 0 5px;
    font-size: 13px;
    color: $gray7;
  }
}

.select-picker-dropdown {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  background: $white;
  top: 105%;
  min-width: 100%;
  padding: 5px 0;
}

.select-picker-dropdown-item {
  padding: 7px 15px;
  background: none;
  border: none;
  display: block;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  user-select: none;

  &:hover, &:focus {
    color: $white;
    background-image: linear-gradient(to bottom, $blue1, $blue14);
    outline: none;
  }
}

.select-picker-large {
  margin-top: -8px;

  .select-picker-target button {
    font-size: 18px;
    padding: 7px 15px;
  }

  .select-picker-dropdown {
    .select-picker-dropdown-item {
      font-size: 18px;
    }
  }
}
