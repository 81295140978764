@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/tables';
@import '../../../constants/variables';

.billing-report-modifiers {
  width: initial;
  height: 100px;
  background-color: lightgrey;
  margin-top: 10px;

  .vertical {
    display: block;
  }
  .controls:has(label.vertical) {
    display: contents;
  }
  label.control-label {
    width: auto;
  }
  .grouping {
    float: left;
    margin-left: 30px;
  }
  .date-grouping {
    float: left;
    margin-left: 30px;
  }
  .date-range {
    float: right;
  }
  .breakdown {
    float:left;
    margin-left: 30px;
    line-height: 1.5;
    label {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}

.contact-filters-react {
  width: 100%;
}

.report-titles {
  float: left;
  padding-left: 10px;
  padding-right: 10px;

  &.selected {
    border-bottom: 1px solid red;
  }
}

.billing-report--card {
  border: 1px solid black;
  float: left;
  width: 200px;
  height: 200px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;

  .billing-report--card--header {
    font-weight: bold;
    font-size: large;
  }
  .billing-report--card--body {
      color: grey;
  }
}

.billing-report-item-list {
  width: auto;

  .account_name {
    width: 150px;
    padding-left: 30px;
    text-indent: -25px;
  }

  .student_name {
    width: 150px;
    padding-left: 30px;
    text-indent: -25px;
  }

  .billing-category-name {
    padding-left: 35px;
    width: 150px;
  }

  .tier_1_billing_category_name, .tier_3_billing_category_name {
    width: 150px;
  }

  .charge_total, .credit_total, .payment_total {
    width: 120px;
  }

  td {
    width: 100px;
  }
}
