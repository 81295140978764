@import '../../../constants/variables';

.billing-dashboard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;

  hr {
    width: 100%;
  }

  .billing-dashboard-graph {
    min-height: 300px;
  }

  .billing-dashboard-historical-section {
    flex: 1 0 30%;
    display: flex;
    flex-direction: column;
  }

  .billing-dashboard-historical-filter {
    flex: 0 0 auto;
  }
  .billing-dashboard-historical-graph {
    flex: 1 0 10%;
  }

  .billing-dashboard-date-section {
    flex: 2 0 60%;
  }

  .billing-dashboard-date-bar {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex: 0 0 auto;
  }

  .billing-dashboard-totals {
    text-align: center;
    th {
      border: solid 1px black;
      padding: 5px;
    }
    td {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .billing-dashboard-charged-collected {
    flex: 3 0 30%;
  }

  .billing-dashboard-pies {
    display: flex;
    justify-content: space-evenly;
    flex: 2 0 20%;
    margin-top: 40px;
  }

  .billing-dashboard-pie-graph {
    max-width: 400px;
    max-height: 400px;
    min-height: 165px;
  }

  .radio-filter-label {
    padding: 8px;
  }
}

