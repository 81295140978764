@import '../../../../../../stylesheets/helpers/variables';
@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.admin #container .reviewing {
  h1 {
    font-size: 22px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  h2 {
    display: block;
    color: $body-color;
    margin: 0 0 15px 0;
    padding: 10px 0 2px 0;
    line-height: 21px;
    font-weight: 500;
    font-size: 21px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $divider;
  }

  a.editable-click,
  a.editable-click:hover {
    color: $link-color;
  }
}
