@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';
@import '../../../../constants/variables.scss';

.sp-profile-section-cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
}

.sp-profile-section-empty {
  svg { color: $gray3; }
}
