@import '../../../constants/variables';


/* Fix delete confirm dialog */
.static-modal {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 15px;
  }

  .modal {
    top: 10%;
  }
}

.communication-plan-list {
  th:nth-child(6),
  td:nth-child(6) {
    text-align: center;
  }

  .managed-list-item-action  {
    margin: 0 5px 0 0;
  }
}

.communication-plan-trigger em {
  font-style: italic;
  font-weight: bold;
}

.communication-plan-inactive {
  color: $gray20;
  font-style: italic;

  code {
    color: $gray20;
  }
}

tr:not(.communication-plan-inactive) .communication-plan-schedule-expired {
  color: $red8;
}

.communication-plan-criteria {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  code {
    border: none;
  }
}

.communication-plan-activate-button {
  border: 1px solid $gray21;
  padding: 0 3px;
}
