@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';
@import '../../../constants/variables.scss';

.sp-profile-section header {
  display: flex;
  margin-bottom: 12px;
  font-size: 18px;

  > * {
    margin-right: 8px;
    color: $blue0;
  }

  .sp-profile-section-header-title {
    font-weight: 600;
  }

  .sp-profile-section-header-add {
    margin-left: 6px;
    color: $green1;
    font-size: 20px;
  }
}
