
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/tables';
@import '../../../constants/variables';

.contact-list-react {
  @extend .table;

  margin-top: 45px;
}

.contact-sub-list-react {
  @extend .table;

  margin-top: 10px;
}

.contact-list-react-bulk {
  width: 5px;

  input {
    margin: 0;
  }
}

.contact-list-react-header a {
  margin: 0 5px 0 0;

  &:link, &:hover, &:visited, &:active {
    color: inherit;
  }
}

.contact-list-react-name > .placeholder-text {
  top: 0 !important;
  height: 18px !important;
  animation-duration: 3.5s;

  .content-line {
    height: 0 !important;
  }
}

.contact-list-react-name > a {
}

.contact-list-react-year-select {
  &.dropdown-toggle.btn.btn-secondary {
    background: white;
    box-shadow: none;
  }
}

.contact-list-react-pagination {
  overflow: hidden;
  clear: both;
  padding: 10px 0 0 0;
}

// Field types
.contact-list-react-type-number, .contact-list-react-header-type-number {
  text-align: right;
}

.contact-list-react-type-currency, .contact-list-react-header-type-currency {
  text-align: right;
}

.contact-list-react-type-date, .contact-list-react-header-type-date {
  text-align: right;
}

.contact-list-react-type-icon, .contact-list-react-header-type-icon {
  text-align: center;
  width:1%;
  white-space:nowrap;
}

button.contact-list-react-expand-row {
  border: 0;
  background-color: transparent;
  line-height: 1;
}

.contact-list-react-row-expanded {
  border-bottom: 2px solid $table-border-color;
}

.contact-list-react-page-title {
  display: inline-block;
}

.contact-list-react {
  /* Checkbox */
  .contact-list-react-checkbox {
    cursor: pointer;
    visibility: hidden;

    &:checked {
      visibility: initial;
    }
  }

  tr:hover {
    .contact-list-react-checkbox {
      visibility: initial;
    }
  }

  /* horizontal black rows */
  th {
    border-top: 1px solid black;
  }

  tr {
    border: none;
    td {
      border-bottom: 1px solid;
    }
  }

  /* rows with sub-rows */
  .contact-list-react-grouped-rows {
    tr {
      td {
        border-bottom: none;
      }
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid black;
      }
    }

    .contact-list-react-sub-row {
      color: $gray14;
      border-color: black;
    }
  }
}
