@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-setup-decision {
  @include make-row();
}

.review-setup-decision-types {
  @include make-col-ready();
  @include make-col(18);
}

.review-setup-decision-type {
  @include make-row();

  &:hover .review-settings-item-remove-button {
    display: inline-block;
    color: $red1;
  }
}

.review-setup-decision-type-input {
  @include make-col-ready();
  @include make-col(10);

  margin-bottom: 8px;
}

.review-setup-decision-label {
  @include make-col-ready();
  @include make-col(6);

  text-align: right;
}
