@import '../../../../stylesheets/shared/helpers/bootstrap_variables';

.link-button {
  color: $primary;
  border: 0;
  background: transparent;
  padding: 0;
  vertical-align: baseline;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}
