@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-sortable-list-cover-sheet-field-name {
  @include make-col(20);
}

.review-sortable-list-cover-sheet-actions {
  @include make-col-ready();
  @include make-col(3);

  text-align: center;
}
