@import '../../../constants/variables.scss';

.review-reading-progress-cell {
  text-align: center;
  cursor: pointer;
}

.review-readers-complete {
  color: $green1;
}

.review-reading-progress-cell__popup-header {
  font-weight: bold;
}