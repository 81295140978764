@import '../../../constants/variables';

.billing-categories-report-table{
  border-collapse:collapse;
  thead{
    th{
      border: none
    }
  }
  tbody {
    tr{
      td {
        border: none;
      }
    }
  }

}

.billing-category-report-sub-total{
  color: $gray17;
}

.billing-categories-category-row{
  border-top: $black 1px solid;
}
