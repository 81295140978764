@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';


.refund-form {
  .refund-partial-amount{
    display: flex;
    margin-bottom: .5rem;
    width: 100%;
  }

  .col-form-label {
    font-weight: bold;
  }
}

.table {
  .text-end {
    text-align: right;
  }
}

.refund-totals {
  font-weight: bold;
}

.refund-partial-amount {

  .amount-label, .amount-help {
    padding: 4px 5px 4px 0;
  }

  .amount-label {
    margin-left: 20px;
    text-align: right;
  }

  .amount-help {
    width: 35%;
  }

  input {
    width: 40%;
    @extend .form-control;
    @extend .form-control-sm;
  }
}
