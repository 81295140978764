@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';

.react-datepicker__close-icon:after {
    background-color: $white;
    color: $gray14;
}

.react-datepicker__close-icon:hover:after {
    color: $red12;
}
