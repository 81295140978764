@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '../../../../../javascripts/react/constants/variables.scss';

.sp-header-top {
  padding-left: 0;
}

.sp-layout-header {
  height: 60px;
  background-color: #595959;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.sp-layout-header-interspace {
  flex-grow: 1;
}

.sp-container {
  max-width: 1320px;
  width: 100%;
  padding-left: var(--bs-gutter-x, 2rem);
  padding-right: var(--bs-gutter-x, 2rem);
  @include media-breakpoint-down(md) {
    padding-left: var(--bs-gutter-x, 1rem);
    padding-right: var(--bs-gutter-x, 1rem);
  }
  margin-right: auto;
  margin-left: auto;

  .page-menu {
    display: flex;
    align-items: center;
    height: 100%;

    span {
      height: 100%;
    }
  }

  .sp-header-list {
    display: flex;
    background: none;
    margin: 0;
    height: 100%;
    padding-left: 0;

    li {
      height: 100%;
      width: fit-content;
      margin: 0;
      padding: 0 20px;
      display: flex;
      align-items: center;

      a:link,
      a:visited,
      a:hover,
      a:active,
      button {
        color: $white;
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
      }
    }

    li.active {
      background: none;
      background-color: $gray79;
    }

    .hover-menu-area {
      @include media-breakpoint-down(md) {
        .sp-person-name {
          display: none;
        }
      }

      .hover-menu-dropdown {
        background-color: $gray8;
      }

      .hover-menu-item:hover {
        background-color: $gray79;
      }
    }
  }
}

.sp-layout-main {
  background-color: $white;
}

.sp-hamburger {
  display: flex;
  text-align: center;
  align-items: center;
}
