@import "../../../constants/variables.scss";
@import "../../../../../stylesheets/helpers/variables";

.cross-app-reports {
  display: flex;
  column-gap: 50px;
}

.cross-app-current {
  flex: 1 1 60%;
}

.cross-app-historical {
  flex: 1 1 40%;
}

.cross-app-historical-school {
  margin-top: 20px;
}

.cross-app-count {
  width: 100px;
}

.cross-app-percent {
  width: 80px;
}

.cross-app-progress {
  width: 35%;
}

.cross-app-total-count {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cross-app-current-table {
  margin-top: 0;
}

.cross-app-filters {
  margin-top: 24px;
}

.cross-app-year {
  font-size: 18px;
  font-weight: bold;
  color: $gray1;
}
