@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';
@import '../../constants/variables';

.add-reminder {
  padding: 10px;
  border-radius: 5px 5px 0 5px;
  background: $gray5;

  label {
    font-size: 11px;
  }
}

.add-reminder-body {
  @extend .form-label;
  width: 100%;
}

.add-reminder-buttons {
  overflow: hidden;
  margin: -1px 0 0 0;
}

.add-reminder-button {
  color: $gray31;
  float: right;
  padding: 5px 25px;
  font-size: 13px;
  border: 0;
  background: $gray5;

  &:focus {
    outline: none;
  }
}

.add-reminder-submit {
  border-radius: 0 0 0 5px;
  color: $white;
  background: $green1;

  &:hover {
    background: $green0;
  }
}

.add-reminder-close {
  border-radius: 0 0 5px 0;

  &:hover {
    background: $gray3;
  }
}

.add-reminder-owner-text, .add-reminder-due-date-text {
  display: block;
}

.add-reminder-metadata {
  overflow: hidden;
}

.add-reminder-body-text {
  @extend .form-control;
}

.add-reminder-owner {
  float: left;
}

.add-reminder-due-date {
  float: right;

  input {
    width: 100px;
  }
}

.add-reminder-owner-text, .add-reminder-due-date-text {
  margin: 0 5px 0 0;
}

.add-reminder-due-date-label {
  float: right;
}

.add-reminder-error {
  border: 1px solid rgba(180, 18, 27, 0.8);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(180, 18, 27, 0.6);
}
