@import '../../../constants/variables';

#container .test-score-group {
  padding: 0 0 20px 0;
  float: left;


  .test-score-table{
    float: left;
    position: relative;
    bottom: 10px;
    left: 10px;
  }

  .test-score-header {
    color: black;
    font-size: 12px;
    padding: 10px 0 10px 7px;
    position: relative;
    left: 13px;
  }

  .divider {
    border-left: 1px solid $gray2;
  }

  .score-name{
    padding: 7px 8px 5px 14px;
    font-weight: normal;
  }

  .score{
    padding: 7px 8px 0 20px;
    font-weight: normal;

  }
}
