@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-field-display_name {
  a {
    color: $gray77;

    &:hover {
      text-decoration: underline;
      color: $gray77;
    }
  }
}
