@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/tables';
@import '../../../constants/variables';

.grouped-list-react {
  width: auto;
  min-width: 800px;

  tr {
    padding-left: 25px;
  }

  thead {
    background-color: lightgrey;
    line-height: 35px;
  }

  tbody {
    line-height: 25px;
    tr.tier0 {
      background-color: #f3f4f4;
      font-weight: bold;
    }
  }
}

.reader-scores-report {
  white-space: pre-wrap;
}