.update-autopay-dates-buttons { //save button
}
.billing-account-autopay-plan { // single plan
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  select {
    margin-top: 0px;
    margin-bottom: 0px;
    width: auto;
  }
}

.billing-account-autopay-editor{ // overall one
  margin: 5px;
}

.autopay-plan-description {
  .helper-text {
    display: block;
    font-style: italic;
    color: #7A7A7A;
    font-size: 12px;
  }
}
