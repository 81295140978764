@import '../../../constants/variables';

.bulk-file-workflow-waiting {
  border: 2px dashed darkgray;
  color: darkgray;

  padding: 10px;
  border-radius: 6px;
  text-align: center;

  .waiting-reason {
    font-size: 24pt;
    margin: 10px;
  }
  .waiting-spinner {
    font-size: 48pt;
    margin: 5px;
  }
  .waiting-explanation {
    font-size: 12pt;
    margin: 10px;
  }
}

.bulk-file-workflow-user-matching {
  height: 100%;
  width: 100%;

  .user-match-page-header {
    position: relative;
    top: 0;
  }

  .bulk-file-page-inspect {
    border: 1px solid darkgray;
    border-radius: 5px;
    background: lightyellow;
    padding: 10px;

    .bulk-file-page-inspect-label {
      font-weight: bold;
      margin-right: 5px;
    }

    .bulk-file-page-inspect-value {
      color: darkslategrey;
      padding: 0 3px;
      border-radius: 3px;
      font-family: Lucida Console, Courier, monospace;
    }

    .bulk-file-page-inspect-match {
      padding: 5px;
      margin: 5px;
      border: 1px solid darkslateblue;
      background: aliceblue;
      border-radius: 5px;

      .bulk-file-page-inspect-match-header {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 12pt;
      }
    }

  }

  .user-match-page-body {
    padding: 15px 0;

    width: 100%;
    overflow-y: auto;

    #img {
      max-width: 100%;
    }

    .page-image-loading {
      font-size: 58pt;
      text-align: center;
      color: darkgray;
    }
  }

  .bulk-file-workflow-overview {
    .bulk-file-page-unmatched {
      background-color: lightcoral;
      color: darkred;
    }
  }
}

.user-match-page-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left:0;
  right:0;
  z-index: 999;

  padding: 16px 24px;
  background-color: $gray5;
  border-top: 6px solid $orange2;

  .Select-menu-outer {
    top: auto;
    bottom: 100%;
  }

  overflow-y: visible;

  .auto-match {
    color: green;
    .fa {
      font-size: 12pt;
    }
  }

  .match-details {
    font-size: 10pt;
    color: darkgray;
  }

  .Select-value {
    .non-match {
      color: red;
    }
  }

  .Select-menu-outer {
    max-height: 400px;
  }

  .Select-menu {
    max-height: 398px;
  }
}

.user-match-page-footer-content {
  width: 940px;
  margin: 0 auto;
  text-align: center;
}

.user-match-page-footer-contact-select {
  width: 60%;
  display: inline-block;
  margin-bottom: -15px;
}

.bulk-file-workflow-back {
  display: inline-block;
  margin: 0 0 15px 0;
}

.bulk-file-workflow-download {
  margin: -5px 0 0 15px;
}

.bulk-file-workflow-overview .managed-list-page {
  margin: 30px 0 0 0;
}


.snippet-edited-at-column > a{
  color: black;
}