@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';
@import '../../../../constants/variables';

.review-cover-sheet-notice {
  padding: 12px 14px 20px 14px;
  border: 1px solid $blue2;
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 1px 1px 4px $gray16;
}

.review-cover-sheet-notice-header {
  margin: 14px 0;
  line-height: 21px;
  font-size: 21px;
  color: $primary;
  text-align: center;
}

.review-cover-sheet-notice-instructions {
  display: inline-block;
  list-style: none;
  margin-bottom: 12px;

  li {
    text-align: left;
    margin-bottom: 6px;

    i, svg {
      margin-right: 4px;
    }
  }
}

.review-cover-sheet-notice-dismiss-link {
  font-style: italic;
  margin: 10px 0 0 0;
  cursor: pointer;
  display: inline-block;
}
