
@import '../../constants/variables';

.user-reminders-add {
  border: none;
  color: $white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
}

span.user-reminders-selector {
  color: $teal2;
  cursor: pointer;
  line-height: 24px;

  &:hover {
    text-decoration: underline;
  }
}

select.user-reminders-selector {
  margin: -4px 0 0 0;
}