@import '../../constants/variables';

.reminder-list {
  margin: 10px 0 0 0;
}

.reminder-tagline {
  margin: 5px 0 10px 0;
  color: $gray1;
  font-style: italic;
}

.reminder-list-section-header {
  margin: 0 0 5px 15px;
  font-weight: bold;
  color: $gray1;
}

.reminder-list-overdue {
  .reminder {
    background: $gray33;

    .reminder-complete:hover {
      color: $red1;
    }

    .reminder-complete:active {
      background: $red1;
      color: $white;
    }
  }
}

.reminder-list-today {
  .reminder {
    background: $yellow5;
  }

  .reminder-complete:hover {
    color: $orange3;
  }

  .reminder-complete:active {
    background: $orange3;
    color: $white;
  }
}

.reminder-add {
  display: inline-block;
  margin: -2px 0 0 0;
  color: $green6;
  cursor: pointer;
  font-size: 16px;
  border: none;
  background: transparent;
  transition: all .3s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.4);
  }
}

.reminder-list-selector-description {
  color: $gray1;
  line-height: 24px;
  margin: 0 3px 0 0 ;
}

.reminder-list-exit {
  transition: opacity 500ms ease-in;
}

.reminder-list-exit-active {
  opacity: 0;
}

.reminder-list-loading {
  font-size: 48px;
  text-align: center;
  color: $gray34;
}
