.bulk-file-workflow-create-modal {
  display:block;

  .bulk-file-upload-dropzone {
    // todo : colors

    &.pending {
      border: 2px dashed darkgray;
      color: darkgray;
    }

    &.completed {
      border: 2px dashed green;
      color: green;
    }

    padding: 10px;
    border-radius: 6px;

    .upload-icon {
      font-size: 48pt;
    }
    .upload-label {
      font-size: 24pt;
    }
    .upload-details {
      font-size: 12pt;
    }
  }
}