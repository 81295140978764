@import '../../../constants/variables';

.stripe-payout-overview {
  display: flex;
  justify-content: space-between;

  margin: 20px 80px;
  padding: 19px 60px;
  background-color: $gray28;
  border-radius: 4px;

  .stripe-payout-overview-header {
    text-transform: uppercase;
    font-size: 12px;
    color: $gray35;
    margin-bottom: 3px;
  }

  .stripe-payout-overview-value {
    font-size: 18px;
  }

  .sub {
    white-space: nowrap;
    overflow: hidden;
    color: $gray14;
  }
}

.stripe-payout-detail {
  .stripe-payout-detail__header {
    display: flex;
    justify-content: space-between;
  }
}

.stripe-balance-transactions {
  margin-top: 0px;
}
