.explicit-verification-modal {

  .explicit-verification-checkbox {
    input[type=checkbox] {
      margin-right: 10px;
    }

    font-style: italic;
    margin-bottom: 10px;
  }
}
