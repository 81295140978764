@import '../../../constants/variables';

.unordered-list {
  line-height: 1.5;
  font-size: 1.25em;
}

.unordered-list.warning {
  background-color: $yellow4;
  border: 1px solid $yellow0;
  border-radius: .5em;
  padding: .15em 1em .15em 1em;
  margin: .15em;
}

.unordered-list.danger {
  background-color: $pink0;
  border: 1px solid $orange1;
  border-radius: .5em;
  padding: .15em 1em .15em 1em;
  margin: .15em;
}

.unordered-list.bold {
  font-weight: bold;
}

.unordered-list-attention-message {
  font-family: inherit;
  font-size: 1.5em;
}

.unordered-list-attention-message.danger {
  color: $red1;
}

.unordered-list-attention-message.smaller {
  font-size: 1.15em;
}

.unordered-list-preface {
  font-size: 1.25em;
}
