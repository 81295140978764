.billing-account-overview-balance, .billing-account-overview-profiles {
  margin: 8px 0 15px 0;
  font-size: 15px;
  font-weight: bold;
}

.billing-account-overview-audits {
  display: inline-block;
  margin: 0 0 -4px 10px;
  vertical-align: bottom !important;
}

.billing-account-overview-checkbox-target {
  padding: 5px 0;
  // This is a workaround to show tooltips when the checkbox is disabled.
  // See https://github.com/react-bootstrap/react-bootstrap/issues/2428#issuecomment-407800236
  display: inline-block;
  cursor: not-allowed;
}

.saved-payment-profiles {
  .table {
    width: auto;
  }
}
