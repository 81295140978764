@import '../../../constants/variables';

.preview-snippets-recipient-list {
  padding :0;
  overflow-y: scroll;
  max-height: 400px;
}

.preview-snippets-relation-text {
  color: $gray17;
  font-size: 11px;
  display: block;
  font-weight: bold;
}

.preview-snippets-body {
  font-family: sans-serif;
  width: 660px;
  float: left;
}

.preview-snippets-content {
  margin: 25px 0 0 0;
}
