@import '../../../constants/variables';

.payment-selector {
  section {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
  }

  .payment-ach-toggle {
    width: 100%;
    clear: both;
    margin-bottom: 18px;

    .btn {
      width: 50%;
    }
  }

  fieldset {
    margin-bottom: 10px;
    border: 1px solid $gray2;
    box-shadow: 2px 1px 3px 2px rgba(50, 50, 93, 0.15), 2px 4px 6px 2px rgba(112, 157, 199, 0.15);
    border-radius: 4px;
    border: none;
    background-color: $gray5;

    input {
      height: unset;
    }

    label:not(:last-child) {
      border-bottom: 1px solid $gray29;
    }

    label {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 42px;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      margin-bottom: 0;

      .payment-input-label {
        min-width: 140px;
        padding: 0 15px;
        text-align: right;
      }
    }

    &.payment-profile,
    &.enter-payment-details {
      label {
        justify-content: left;
        align-items: center;
        text-align: left;
        padding-left: 20px;

        input[type=checkbox] {
          margin: 0 10px 0 0;
        }

        input[type=radio] {
          margin: 0 10px 0 0;
        }
      }
    }

    .payment-input-field, .payment-input-field .Select-control {
      flex: 1;
      background: transparent;
      font-weight: 400;
      outline: none;
      cursor: text;
      border: none;
      box-shadow: none;
      margin-bottom: 0;
    }

    .payment-input-field, .payment-input-field {
      padding: 0 15px;
    }

    .payment-input-field-prefix {
      margin-right: -15px;
      color: $gray17;
    }

    .Select-control {
      padding: 0;

      .Select-input, .Select-value, .Select-placeholder {
        padding: 0;
      }

      .Select-placeholder {
        color: $gray30;
      }
    }

    .Select-value {
      padding-left: 0;
    }

    .payment-input-field:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }

  .payment-method-selector {
    border-bottom: 1px solid $gray2;
    margin-bottom: 20px;

    .payment-method-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .payment-method-selection {
      line-height: 18px;
      list-style: none;
      display: inline;
      padding-right: 10px;
      padding-left: 10px;

      &.active {
        border-bottom: 2px solid $gray0;
        font-weight: 700;
      }

      a {
        text-decoration: none;
        cursor: pointer;
        color: $gray0;
        font-size: 16px;
      }
    }
  }

  .save-payment-profile {
    color: darkgreen;
    margin-top: 20px;
    padding-left: 17px;

    .save-payment-profile-checkbox, .autopay-checkbox {
      width: 15px;
      clear: left;
      float: left;
    }

    .save-payment-profile-info {
      float: left;
      padding-left: 10px;
      margin-bottom: 20px;

      .save-payment-profile-header {
        font-weight: bold;
      }
    }

    &.disabled {
      color: lightgrey;
    }
  }

  .convenience-fee {
    margin-top: 5px;

    .convenience-fee-notification {
      padding-left: 0;
    }
  }

  .btn {
    width: 100%;
  }
}

.payment-terms {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 17px;
  color: darkgreen;

  .payment-terms-checkbox {
    width: 15px;
  }

  .payment-terms-description {
    flex-grow: 2;
    padding-left: 10px;

    p {
      margin: 0;
    }
  }
}

.fee-list {
  thead {
    display: none;
  }

  .managed-list-item-actions {
    display: none;
  }

  .managed-list-item {
    font-size: 14px;

    td {
      border-top: none;
      padding: 5px 8px;
    }

    &.convenience-fee {
      font-size: 13px;
    }

    &.fees-total {
      font-weight: bold;
      td {
        border-top: 1px solid $gray12;
      }
    }
  }
}

.kba-questions {
  padding-left: 17px;

  .question {
    margin-bottom: 20px;

    .question-text {
      font-size: 14px;
    }

    .answer {
      display: flex;
      font-weight: normal;

      input[type="radio"] {
        margin: 0 4px;
      }
    }
  }
}
