.event-container {
  display: flex;
  flex-direction: column;
}

.event-row {
  display: flex;
  flex-direction: row;
  padding: 5px 0 0 0;
}


.event-item{
  flex: 1
}

.line{
  border: 1px solid black;
  width: 100%;
  margin: 2px 0 10px 0;
}

.event-list{
  margin: 0;
  padding: 0 0 6px 0px;
}