@import '../../../constants/variables';

.bulk-dropdown-menu .bulk-dropdown-submenu {
  display: none;
  position: absolute;
  top: -9px;
  right: 100%;
  left: auto;
}

.bulk-dropdown-menu > li:hover > .bulk-dropdown-submenu {
  display: block;
}

.bulk-submenu-hover-color:hover {
  background: lightgrey;
}

.bulk-dropdown-menu > li > a.dropdown-toggle:after {
  display: none;
}

.bulk-actions {
  float: right;
  list-style: none;
  margin-block-end: 0;

  .disabled > .dropdown-item{
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.bulk-actions > li {
  float: left;
  margin: 0 0 0 5px;
}

.bulk-action-dropdown {
  left: auto;
  right: 0;
}

.bulk {
  >a {
    border-radius:3px;

    &:after {
      content:none;
    }
  }
}
