/* Correct RuleControl styles for bootstrap 2 */
@import '../../../constants/variables';

.rule-control {
  .control-group {
    margin-bottom: 3px;
  }

  .rule-depth-0 {
    background-color: $white;
    border-color: $gray21;
  }
  .rule-depth-1 {
    background-color: $gray15;
    border-color: $gray22;
  }
  .rule-depth-2 {
    background-color: $gray24;
    border-color: $gray23;
  }
  .rule-depth-3 {
    background-color: $gray25;
    border-color: $gray26;
  }

  .conjunction-divider {
    color: $gray27;
    font-size: 10px;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 0;

    .btn-sm {
      font-size: 10px;
    }
  }

  .btn-group {
    width: 100%;
  }

  select {
    width: unset;
  }
}

