@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-rating-criterion {
  @include make-container();
  
  margin: 15px 0 0 0;
  background: $gray5;
  border: 1px solid $gray4;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
}

.review-rating-criterion-row {
  @include make-row();
}

.review-rating-criterion-label {
  @include make-col-ready();
  @include make-col(6);
  
  text-align: right;
}

.review-rating-criterion-desc,
.review-rating-criterion-input {
  @include make-col-ready();
  @include make-col(12);
  
  @include media-breakpoint-down(lg) {
    @include make-col(10);
  }
}

.review-rating-criterion-desc {
  color: $gray1;
  font-style: italic;
}

.review-rating-criterion-input {  
  margin-bottom: 10px;
  
  .Select {
    .Select-control {
      height: 31px;
    }

    .Select-placeholder {
      line-height: 29px;
    }

    .Select-value {
      margin-top: 4px;
    }

    .Select-input {
      height: 29px;
    }
  }
}

.review-rating-criterion-help {
  /** No make-col-ready() here because buttons already have padding*/
  @include make-col(3);
  
  @include media-breakpoint-down(lg) {
    @include make-col(4);
  }
  color: $gray1;
}

.review-rating-criterion-scale {
  margin: 20px 0;
}

.review-rating-criterion-scale-text {
  line-height: 28px;
  color: $gray1;
}

.review-rating-criterion-add-scale {
  @include make-col-ready();
  @include make-col-offset(6);
  @include make-col(12);
}

.review-rating-criterion-row:hover .review-settings-item-remove-button {
  display: inline-block;
  color: $red1;
}

.review-rating-criterion-delete {
  @include make-col(3);
  
  @include media-breakpoint-down(lg) {
    @include make-col(4);
  }

  text-align: end;

  &:only-child {
    @include make-col(24);
  }
}

.review-rating-criterion-delete-button,
.review-rating-criterion-delete-button:active,
.review-rating-criterion-delete-button:focus,
.review-rating-criterion-delete-button:hover,
.review-rating-criterion-delete-button:visited {
  color: $red1;
  text-align: right;
  display: inline-block;
}
