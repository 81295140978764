@import '../../../constants/variables';
@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';

.api-credential-detail {
  margin-bottom: 1rem;
  border-bottom: 1px solid $gray3;

  code {
    font-size: 13px;
  }

  &:last-child {
    margin-bottom: inherit;
    border-bottom: inherit;
  }

  .detail-item {
    @include make-row();
    margin-bottom: 1rem;
  }

  .detail-item__label {
    @include make-col-ready();
    @include make-col(6);
    @extend .col-form-label;
  }

  .detail-item__detail {
    @include make-col-ready();
    @include make-col(18);
  }
}


