// @import '../../shared/helpers/bootstrap_variables';
@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-settings-save-button {
  margin-left: auto;
  display: block;
}

.review-section-header {
  font-size: 18px;
  color: $primary;
  border-bottom: 1px solid $gray4;
  padding: 0 0 3px 0;
  margin: 0 0 5px 0;
}

.review-section-help-text {
  color: $gray1;
  font-style: italic;
}