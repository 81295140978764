@import "../../constants/variables";
@import "../../../../stylesheets/helpers/variables";
@import '../../../../stylesheets/shared/helpers/bootstrap_variables';
//$inquiry_color: #01497F;
//$application_color: #145DA9;
//$application_complete_color: #218CDB;
//$accepted_color: #5BB35B;
//$enrolled_new_color: #4D8E3F;
//$enrolled_returning_color: #424242;
//$withdrawal_color: #7A7A7A;
//$total_color: #43663D;
//$target_color: #F9F9F9;

.funnel-report-header {
  display: flex;
  justify-content: space-between;

  .report-type-toggle {
    margin-left: 1.5rem;

    .btn-active {
      color: $white;
      background-color: $grey0;
    }
  }
}

.funnel-report-date-filter {
  margin: 16px 0;

  label, button {
    margin-left: 16px;
  }

  .funnel-report-date-picker-container {
    display: inline-block;
    margin: 0 4px;
  }
}

.funnel-report-filters {
  margin-bottom: 2rem;
}

.combined-funnel-report {
  th,
  td {
    text-align: center;
  }
  thead {
    tr {
      th {
        font-weight: normal;
        border-bottom: none;
        border-top: none;
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          border-top: 2px solid $grey0;
        }
      }

      &:last-child {
        td {
          border-bottom: 2px solid $grey0;
        }
      }

      td:first-child {
        text-align: left;
      }
    }
  }

  tfoot {
    tr {
      td:first-child {
        text-align: right;
      }

      td {
        border-bottom: 2px solid $gray1;
      }
    }
  }

  .main_header {
    color: $white;
  }

  .prior_year {
    color: $gray1 !important;
  }

  .col_inquiry {
    &.main_header {
      background-color: $inquiry-objects;
      border-left: 2px solid $inquiry-objects;
    }
    &.current_year {
      border-left: 2px solid $inquiry-objects;
    }
  }

  .col_applicant {
    &.main_header {
      background-color: $applicant-objects;
      border-left: 2px solid $applicant-objects;
    }
    &.current_year {
      border-left: 2px solid $applicant-objects;
    }
  }

  .col_application_complete {
    &.main_header {
      background-color: $application-complete-objects;
      border-left: 2px solid $application-complete-objects;
    }
    &.current_year {
      border-left: 2px solid $application-complete-objects;
    }
  }

  .col_accepted {
    &.main_header {
      background-color: $green2;
      border-left: 2px solid $green2;
    }
    &.current_year {
      border-left: 2px solid $green2;
    }
  }

  .col_enrolled_new {
    &.main_header {
      background-color: $green1;
      border-left: 2px solid $green1;
    }
    &.current_year {
      border-left: 2px solid $green1;
    }
  }

  .col_enrollment_in_progress {
    &.main_header {
      background-color: darkgray;
      border-left: 2px solid darkgray;
    }
    &.current_year {
      border-left: 2px solid darkgray;
    }
  }

  .col_enrolled_returning {
    &.main_header {
      background-color: $gray0;
      border-left: 2px solid $gray0;
    }
    &.current_year {
      border-left: 2px solid $gray0;
    }
  }

  .col_withdrawal {
    &.main_header {
      background-color: $gray1;
      border-left: 2px solid $gray1;
    }
    &.current_year {
      border-left: 2px solid $gray1;
    }
  }

  .col_enrolled {
    &.main_header {
      background-color: $green0;
      border-left: 2px solid $green0;
    }
    &.current_year {
      border-left: 2px solid $green0;
    }
  }

  .col_target.main_header {
    background-color: $gray5;
    border-left: 2px solid $gray2;
    color: inherit;
  }
  th.col_target,
  td.col_target {
    border-left: 2px solid $gray2;
  }

  .prior_year {
    border-left: 1px dashed $gray4;
  }
  .clickable {
    cursor: pointer;
    color: $primary;

    &:hover{
      text-decoration: underline;
    }
  }
}
