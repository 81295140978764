@import '../../../constants/variables';

.margin-direction {
  position: relative;
}

.margin-label {
  position: absolute;
  right: calc(var(--bs-gutter-x) * .5);
  font-size: 10px;
  font-weight: bold;
  border: 1px solid $gray16;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: $gray19;
  line-height: 10px;
}

