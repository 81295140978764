@import '../../../constants/variables';

.xml-viewer-node-even {
  background-color: $gray20;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
}

.xml-viewer-node-odd {
  background-color: $gray36;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
}

.xml-toggle-collapse {
  font-size: 1.75rem;
  float: left;
}
