@import '../../../constants/variables';

.totals-component-wrapper {
  margin: 20px 80px;
  padding: 20px 60px;
  background-color: $gray28;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.totals-component {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(120px, 20%));
  grid-template-rows: 70px;
  column-gap: 10px;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .totals-header {
    text-transform: uppercase;
    font-size: 12px;
    color: $gray35;
    margin-bottom: 3px;
  }

  .totals-value {
    font-size: 18px;
  }
}
