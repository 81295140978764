@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-settings {
  @include make-container();

  .btn-link {
    &:hover, &:focus {
      text-decoration: none;
      box-shadow: none;
    }
  }
}

.review-settings-header {
  margin: 0 0 30px 0;

}

.review-settings-title {
  display: inline;
  margin-right: 8px;
}

.review-settings-body {
  @include make-row();
}

.review-settings-menu {
  @include make-col-ready();
  @include make-col(6);
}

.review-settings-body-component {
  @include make-col-ready();
  @include make-col(18);
}

.review-settings-footer {
  margin: 60px 0 0 0;
}

.review-sub-section-header {
  font-size: 18px;
  font-weight: bold;
  padding: 0 0 3px 0;
}

.review-sub-section {
  margin: 20px 0 0 0;
}

.review-note-header {
  color: $red1;
}

.review-include-message {
  font-weight: bold;
  margin: 25px 0 0 0;
}

.review-settings-item-remove {
  @include make-col(1);
}

.review-settings-item-remove-button {
  display: none;

  span[class^='fa-'] {
    line-height: 18px;
  }
}

.review-settings-add-field {
  display: inline-block;
  color: $green1;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: $green1;
    text-decoration: none !important;
    box-shadow: none;
  }
}

.review-settings-add-field-container {
  @include make-row();

  padding: 15px;
  border-radius: 5px;
  border: 1px solid $gray4;

  button {
    margin-right: 8px;
  }
}

.review-settings-add-field-selector {
  @include make-col-ready();
  @include make-col(12);
}

.review-settings-add-field-buttons {
  @include make-col-ready();
  @include make-col(12);

  text-align: end;
}

.review-note {
  color: $gray1;
  margin: 15px 0 0 0;
}

.review-settings-switch {
  margin-left: 25px;
}

.review-sortable-list-container {
  @include make-container();

  margin: 20px 0;
}

.review-sortable-list-header-row {
  @include make-row();

  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid $gray3;
}

.review-sortable-list-item {
  @include make-row();

  padding: 5px 0;
  border-bottom: 1px solid $gray3;
  background: $white;

  &:nth-child(even) {
    background: $gray4;
  }
}

.review-sortable-list-reorder {
  @include make-col(1);

  text-align: center;
  color: $gray2;
}

.review-sortable-list-header {
  @include make-col-offset(1);
}

.review-sortable-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}