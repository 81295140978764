@import '../../../constants/variables';

.projected-income-list {
  .projected-income-billed-total-col {
    border-left-width: 1px;
    font-weight: bold;
  }

  .projected-income-payment-category-col {
    border-right-width: 1px;
  }

  .projected-income-totals-row {
    font-weight: bold;
  }
}
