.embed-form-item {
  padding: 0 4px 0 0
}

.embed-form-button {
  padding: 16px 0 0 0;
  text-align: right;
}

.embed-form-code {
  white-space: pre-wrap;
  margin: 12px;
}
