.sp-select-kids {
  width: 100%;
  margin-top: 10px;
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.sp-select-kid {
  display: flex;
  gap: 5px;
}

.sp-select-kid-grade-info {
  align-self: center;
}
