@import "../../../../../stylesheets/shared/helpers/bootstrap_variables";

.sa-froala {
  .fr-element {
    font-family: sans-serif;
  }
}

.toolbar-expand {
  display: flex;
  justify-content: right;
  padding-bottom: 4px;
}

.sa-froala-code-view-alert {
  padding-right: 14px;

  button {
    float: right;
  }
}

.sa-froala .fr-wrapper {
  min-height: 320px;
}

.fr-input-line input[type="text"] {
  padding-top: 14px !important;
}

.fr-code {
  width: 100% !important;
}
