.date-filter-input {
  margin: 5px 8px 0 5px;
}

.date-filter-label {
  display: inline-block
}

.date-filter-custom-date-span {
  padding: 0 5px;
}