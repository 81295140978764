@import '../../../../constants/variables';
@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.contract-bulk-loading {
  font-size: 18px;
  text-align: center;
  padding: 2em;
}

.contract-bulk-count-message {
  font-size: 18px;
  text-align: center;
  padding: 2em;
}

.contract-process-help-text {
  margin: 1.5em 0 1.5em 0;
  p {
    font-size: 1.25em;
  }

  span {
    padding: 0 0 0 .25em;
  }
}

.contract-field-completion-error {
  background-color: $pink0;
  border: 1px solid $orange1;
  border-radius: .5em;
  padding: .15em 1em .15em 1em;
  margin: .15em;
}

.contract-bulk-danger-permanent {
  color: $red1;
}

.submit_contract_form-group {
  @include make-row();

  margin-bottom: 15px;
}

.submit_contract_label {
  @include make-col-ready();
  @include make-col(6);

  padding-top: 0;
  text-align: right;
}

.submit_contract_control {
  @include make-col-ready();
  @include make-col(18);
}
