@import '../../constants/variables.scss';

.Select {
  &.is-focused:not(.is-open) > .Select-control{
    border-color: $teal2;
  }

  &.Select--multi{

    .Select-value {
      background-color: rgba($teal2, 0.08);
      border-color:  rgba($teal2, 0.24);
      color: $teal2;
    }

    &.is-disabled .Select-value {
      background-color: $gray13;
      border-color: $gray19;
      color: $gray59;
    }
  }

  .Select-option.is-focused {
    background-color: rgba($teal2, 0.08);
  }

  .Select-option.is-selected {
    opacity: 0.5;
  }
}