@import '~react-select/dist/react-select.css';

.token-selector {
  margin: 0 0 10px 0;

  .Select-menu-outer {
    z-index: 1052;
  }
}

.token-category {
  width: 25%;
  float: left;
  margin: 0 5px 0 0;
}

.token-select {
  width: 60%;
  float: left;
}

.token-insert {
  width: 10%;
  float: right;
  height: 36px;
}
