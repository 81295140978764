@import '../../../constants/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';

.time-picker {
  display: inline-block;

  .separator {
    margin: 0 5px;
  }

  input {
    appearance: textfield !important;
    width: 50px !important;
    display: inline-block !important;
  }

  select {
    width: 75px !important;
    display: inline-block !important;

    margin: 0 5px;
  }
}



