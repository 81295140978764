@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/tables';
@import '../../../constants/variables';

.contract-fee-table {
  margin-top: 0;
  th {
    width: 10%;
  }
  th:nth-child(1) {
    width: 1%;
  }

  th:nth-child(8), th:nth-child(9), th:nth-child(10) {
    width: 8%;
  }

  th:nth-child(11) {
    width: 16%;
  }
}

.contact-rule-table {
  @extend .table;

  margin-top: 10px;

  th {
    width: 10%;
  }

  th:nth-child(1) {
    width: 10%;
    padding-left: 0;
  }

  th:nth-child(2) {
    width: 50%;
    padding-left: 0;
  }

  th:nth-child(3), th:nth-child(4), th:nth-child(5) {
    width: 8%;
  }

  td:nth-child(1) {
    padding-left: 0;
  }

  th:nth-child(6) {
    width: 14%;
  }
}

.view_changes_button, .edit_contract_rule, .activate_contract_fee, .add_rule, .edit_contract_fee {
  color: $teal2;
  cursor: pointer;
}

.delete_contract_rule, .deactivate_contract_fee {
  color: $red;
  cursor: pointer;
}

.add_rule, .edit_contract_rule, .edit_contract_fee {
  margin-right: 5px;
}

.amount_column, .last_edit_column, .edit_history_column {
  text-align: left;
}
