@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';

.billing-item-editor {
  .billing-contact {
    margin-top: 9px;
  }

  .billing-item-editor-radio-label {
    @extend .form-check-label;
  }

  .billing-item-editor-radio-btn {
    @extend .form-check-input;
  }

  .billing-item-editor-remove-button {
    margin-top: 3px;
    margin-bottom: 10px;
  }
  .billing-item-editor-radio{
    @extend .form-check;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .billing-item-editor-subfield,
  .billing-item-editor-subfield-date {
    @include make-row();

    .control-label {
      @include make-col-ready();
      @include make-col(8);
      float: none;
    }

    .controls {
      @include make-col-ready();
      @include make-col(10);
      margin: 0;
    }
  }

  .billing-item-editor-subfield-date {
    .react-datepicker__input-container {
      input {
        @extend .form-control;
        @extend .form-control-sm;
      }
      .react-datepicker__close-icon {
        padding-top: 0.5rem;
      }
    }
  }

  .billing-item-editor-subfield {
    select {
      @extend .form-select;
      @extend .form-select-sm;
    }
  }

  .billing-item-date {
    .help-inline {
      /* Overrides "inline-block" since the react-date-picker popup causes inline elements to reflow */
      display: block;
    }
  }

  .billing-item-preview-contact-column {
    width: 40%;
  }

  .billing-item-preview-expand-button {
    border: 0;
    background-color: transparent;
    line-height: 1;
  }
}
