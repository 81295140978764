@import '../../../constants/variables';

.billing-students-list .filters {
  margin: 0 0 15px 0;
}

.billing-students-list .track-silo {
  margin: 0 0 0 5px;
}

.billing-students-list  {
  .contact-list-react-type-overdue-flag,
  .contact-list-react-header-type-overdue-flag {
    text-align: center;
  }
}

.contact-list-react-prompt-select-all {
  margin-top: 10px;
  line-height: 30px;
  background: $gray15;
  font-weight: lighter;
  font-size: 16px;
  text-align: center;

  .link-button {
    font-size: 16px;
    font-weight: lighter;
  }
}
