@import '../../../constants/variables.scss';

#list-page-config.setup .list-page-select {
  margin-bottom: 15px;

  .form-label {
    margin-bottom: 5px;
  }

  .Select {
    width: 560px;

    .Select-value {
      display: block;
    }

    .option-group {
      font-weight: bold;
      font-size: 11px;
      color: $gray0;
    }

    .option-option {
      padding-left: 0.5rem;
    }
  }
}