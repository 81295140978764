.contact-list-comment-row__comment-row {
  margin: 4px 0;
  display: flex;
}
.contact-list-comment-row__field-name {
  width: 224px;
  padding-right: 10px;
}
.contact-list-comment-row__comment-text {
  width: 609px;
}