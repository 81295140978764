.stripe-bank-account-modal {
  form {
    .row {
      margin-bottom: 8px;

      .form-label {
        text-align: right;
      }

      select {
        margin: 0;
      }
    }
  }
}