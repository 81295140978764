@import '../../../constants/variables';

// TODO: These class prefixes can become .contact-filters when we kill off the non-react styles
.contact-filters-react {
  margin: 25px 0 0 0;
}

.contact-filters-react-options {
  font-size: 11.9px;
  padding: 0 5px;
  line-height: 17px;

  .fa {
    margin-left: 3px;
  }
}

.contact-filters-react-clear-filters, .contact-filters-react-clear-filters:hover {
  font-size: 11.9px;
  padding: 0 5px;
  line-height: 17px;

  color: $red1;

  .fa {
    margin-right: 3px;
  }
}

.contact-filters-react-search {
  font-size: 11.9px;
  padding: 0 5px;
  line-height: 17px;

  .fa {
    margin-right: 3px;
  }
}

.contact-filters-react-term {
  margin-left: 5px;
}

.contact-filters-react-term-field {
  font-weight: bold;
  margin-right: 3px;
}

.contact-filters-react-term-values {
  font-weight: normal;
}

.contact-filters-react-body {
  margin-top: 10px;
}

.contact-filters-react-count {
  font-size: 18px;
  font-weight: bold;
  color: #7a7a7a;
  padding: 0 5px;
  vertical-align: middle;
}

.contact-filters-react-sublabel {
  padding: 0 5px;
  font-weight: lighter;
  padding: 0 5px;
}

.contact-filter-react-header {
  font-weight: bold;
}

.contact-filter-react-buttons {
  display: flex;
  flex-direction: row;
}

.contact-filter-react-errors {
  padding: 7px 0;
  margin-left: 12px;
  color: $red1;
}

.contact-filter-react-checkboxes {
  //margin-bottom: 10px;
  margin-bottom: .75rem;
  padding-bottom: .75rem;
  border-bottom: 1px solid $gray3;

  label {

    &.checkbox {
      min-height: 28px;

      input[type='checkbox']{
        margin-right: 5px;

      }
    }

  }

  .Select-control, .Select-menu-outer {
    width: auto;
    min-width: 150px; // Arbitrary, but close to what auto seems to put regular text fields
  }
}

.contact-filters-react {
  .form-control {
    width: auto;
  }
  .text-choice-box{
    margin-bottom: 15px;
    .text-choice-label {
      padding-right: 15px;
    }
  }
}
