@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';
@import '../../../constants/variables.scss';

.sp-household {
  text-align: center;
  flex: 0 0 auto;
  display: block;
}

.sp-household-icon {
  border-radius: 50%;
  color: $gray6;
  background-color: $gray4;
  height: 60px;
  width: 60px;
  text-align: center;
}
