@import '../../../../constants/variables';

.billing-account-section:not(:first-child) {
  margin-top: 48px;
}

.billing-account-overview {
  font-size: 13px;
  margin-bottom:15px;

  .billing-account-balance {
    font-size: 16px;
    margin-bottom:10px;
  }

  .btn {
    font-size:13px;
  }


  .billing-account-upcoming{
    margin: 10px 0 0 0;
  }

  .billing-account-autopay-days {
    margin: 10px 0 0 0;
  }

  .helper-text{
    font-style: italic;
    color: $gray1;
    font-size: 12px;
  }

  .green{
    color: green;
  }

  .billing-amount {
    font-weight: bold;
    text-align: right;
    padding: 0 5px 0 5px;
  }

  .next-installment-label, .billing-amount.upcoming {
    border-bottom: 1px solid black;
  }

  .error-text {
    color: $red0;
  }

  .billing-autopay-enabled {
    .fa {
      color: $blue2;
    }
  }

  .controls {
    select {
      width: 200px;
      display: inline-block;
      margin-right: 5px;
    }
  }

}

.billing-history {
  margin-top: 24px;

  .billing-history-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    position: relative;
    border-bottom: 1px solid $teal2;
    padding-bottom: 4px;
    margin: 8px 0 13px;

    .statement-year-selector{
      float: right;
      font-size: 14px;

      .bulk-actions{
        .bulk-action{
          a{
            color: black;
          }
        }
      }
    }

    .tax-history-statement-link{
      padding: 10px;
    }

    .billing-history-statement-link {
      float: right;
      font-size: 14px;
      color: black;
      padding-left: 2rem;
    }
  }
}

.billing-account {
  h2 {
    position: relative;
  }

  .billing-account-selector {
    float: right;
    font-size: 14px;

    .bulk-actions{
      .bulk-action{
        background: white;
        a{
          color: black;
        }
      }
    }
  }
}

.billing-widget {
  margin: 0 0 15px 0;
}

.edit-profile, .delete-profile, .add-profile {
  white-space: nowrap;
}
