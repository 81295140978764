@import '../../../constants/variables';

.email-modal-field-group {
  margin: 0 0 10px 0;
  overflow: hidden;
}

.email-modal-label {
  font-weight: bold;
  width: 25%;
  float: left;
  text-align: right;
  padding: 0 10px 0 0;
}

.email-modal-value {
  width: 70%;
  float: left;
}

.email-modal-attachment {
  padding: 5px 15px;
  display: inline-block;
  margin: 5px 3px 0 0;
  background: $gray5;
  border-radius: 4px;
}

.email-date-filter{
  padding: 15px 0 0 0;
}



