.managed-list-item-actions button {
  margin: 0 5px 0 0;
}

.snippet-list {
  .contact-filters-react {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
