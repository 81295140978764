@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';
@import '../.././../../constants/variables.scss';

.sp-profile-card {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  margin-right: 16px;
  margin-bottom: 16px;
  width: 500px;
  background-color: $gray5;
  border-radius: 8px;
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }

  > * {
    padding: 12px 18px;
  }

  .sp-profile-card-icon {
    flex: 1;
  }

  .sp-profile-card-content {
    flex: 2;
    flex-basis: auto;
    color: $gray1;
    @include media-breakpoint-down(sm) {
      flex-basis: 250px;
      order: 3;
    }
  }

  .sp-profile-card-actions {
    flex: 1;
    text-align: right;

    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
  }

  .btn-outline-primary,
  .btn-outline-danger
  {
    white-space: nowrap;
  }

  .sp-profile-card-heading {
    font-weight: 600;
  }
}
