@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/forms';
@import '../../../constants/variables.scss';



.split-deposit-payment {
  .selectable-split-deposit {
    margin-bottom: 5px;
  }

  border-bottom: 1px solid $gray0;
  margin-bottom: 5px;
}
