@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/forms";
@import "../helpers/_variables.scss";

.report-wrapper {
  margin: 0 0 50px 0;
}

.report-error {
  text-align: center;
  padding: 100px 0 0 0;
}

.state-icon {
  font-size: 48px;

  .active,
  .draft,
  .retired {
    margin-left: 15px;
  }

  .active {
    color: $green;
  }
  .draft {
    color: $red;
  }
  .retired {
    color: $grey0;
  }
}

.form-header {
  color: $teal2;
  font-weight: 400;

}
.retired-header{
  cursor: pointer;
}

.hide {
  display: none;
}

.draft-report,
.active-report,
.retired-report {

  .state-col {
    width: 95px;
  }

  .date-col,
  .comment-col,
  .user-col {
    min-width: 205px;
  }

  .button-col{
    width: 120px;
  }
}
.reporting {
  .border-header {
    /* Replaced .page-header class - removed in Bootstrap 4*/
    color: $medium-grey;
    margin: 20px 0 0 0;
    border-bottom: 1px solid $medium-grey;
    line-height: 22px;
    font-weight: bold;

    .title {
      font-size: 18px;
      text-transform: uppercase;
    }
  }

  .date-filters {
    margin: 15px 0 15px;

    label {
      white-space: nowrap;
    }

    .options {
      form {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        .option-group {
          display: flex;
          flex-wrap: nowrap;
          gap: 5px;
        }

        .form-text {
          white-space: nowrap;
        }

        input[type="text"] {
          max-width: 220px;
        }
      }
    }
  }

  .report-header {
    font-size: 18px;
    color: $light-grey;
    margin: 20px 0 10px 0;

    &.divider {
      height: 23px;
      background: url("../../images/admin_portal/reporting/divider_to_sub.png") repeat-x;

      .arrow-container {
        float: right;
        width: 17%;
        text-align: center;
      }
    }
  }

  .divided {
    border-left: 1px solid $divider;
  }

  .options {
    margin: -10px 0 -20px 0;
  }

  .funnel-report.loading {
    text-align: center;
    margin: 100px 0 0;

    i {
      font-size: 36px;
    }
  }

  .funnel-report {
    .report {
      td {
        font-size: 18px;
        padding: 7px;
      }
    }
  }

  .report {
    font-size: 13px;
    margin: 0 0 20px;

    &.text-center {
      .table {
        th,
        .data {
          text-align: center;
        }
      }
    }

    &.five-col {
      &.with-y-label {
        .row-label {
          width: 13%;
          border-left: 0;
        }
      }

      .row-label {
        width: 15%;
      }

      td {
        width: 17%;
      }
    }

    &.four-col {
      &.with-y-label {
        .row-label {
          width: 13%;
          border-left: 0;
        }
      }

      .row-label {
        width: 15%;
      }

      td {
        width: 21.25%;
      }
    }

    &.sub-report {
      .table-striped > tbody > tr:nth-child(even) > td,
      .table-striped > tbody > tr:nth-child(even) > th {
        background-color: $divider;
      }
    }

    td {
      padding: 5px;
    }

    tbody {
      tr:first-of-type {
        td {
          border-top: 1px solid $medium-grey;
        }
      }

      tr:last-of-type {
        td {
          border-bottom: 1px solid $medium-grey;
        }
      }
    }

    th {
      font-weight: 300;
      font-size: 18px;

      .tooltip {
        font-weight: normal;
      }

      .date {
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        margin: 5px 0 0 0;
      }
    }

    td i.fa.fa-info-circle {
      color: $light-grey;
    }

    .table-striped > tbody > tr:nth-child(odd) > td,
    .table-striped > tbody > tr:nth-child(odd) > th {
      background-color: $white;
    }

    .table-striped > tbody > tr:nth-child(even) > td,
    .table-striped > tbody > tr:nth-child(even) > th {
      background-color: $lighter-grey;
    }

    .table-striped > tbody > tr:nth-child(even) > td.highlighted,
    .table-striped > tbody > tr:nth-child(even) > th.highlighted {
      background-color: $divider;
    }

    td.row-label {
      font-weight: bold;
      font-size: 14px;
      width: 30%;
      text-align: left;
    }

    .target.admin:hover {
      margin-left: 14px;
    }

    .target.admin:hover:after {
      font-family: FontAwesome;
      content: "\F040";
      width: 13px;
    }

    .snapshot-header {
      color: $teal;
    }

    .totals {
      td {
        font-weight: bold;
        font-size: 18px;
        padding: 7px;
      }
    }

    .set-targets {
      color: white;
    }
  }
}

.reports-school-selector-label {
  width: auto !important;
  text-align: left !important;
}

.reporting-header {
  display: flex;
  justify-content: space-between;

  .reports-school-selector {
    display: flex;

    select {
      @extend .form-select;
      @extend .form-select-sm;
      margin-left: 8px;
    }
  }
}
