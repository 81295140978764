.payment-plan-list .track-silo {
  margin: 0 0 0 5px;
}

.payment-plan-list-header {
  margin: 0 0 15px 0;
}

.payment-plan-list {
  /* hide row border when dragging */
  .table tr.sortable-dragging td {  
    border-top: none;
  }

  tr.sortable-dragging td:not(.sortable-dragging-visible) {
    display: none;
  }
}
