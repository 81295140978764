@import '../../../constants/variables';

.ach-setup-area, .cc-setup-area, .cc-payment-country, .sa-payment-fees {
  padding: 1em;
}

.cc-setup-area {
  margin-bottom: 4em;
}

.onboarding-step {
  float: left;
  margin-right: 5em;
}

#test-mode-warning {
  color: $red0
}
