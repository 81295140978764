.account-balances-list {

  &.table {
    max-width: 50%;
  }

  th {
    border-top: none;
  }

  .account-balance {
    text-align: right;
  }

  .link-button {
    text-decoration: none;
    .fa {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
}
