.power-school-contacts-debug {
  .input-group {
    input {
      padding: 6px;
    }
  }

  .expansion__name, .extension__name, .studentdetail__name {
    font-weight: bold;
    margin-right: 1em;
  }

  .expansion__level {
    margin-left: 1em;
  }

  tr {
    td:first-child {
      width: 25%;
    }
  }
}
