@import "../../../constants/variables";

.billing-item-list-filters {
  margin-top: 10px;

  b {
    margin-left: 10px;
  }
}

.billing-item-list-actions {
  float: right;
  margin-bottom: 10px;

  .fa {
    margin-right: 4px;
  }
}

.billing-item-list.table {
  margin-top: 24px;

  td {
    vertical-align: top;
  }

  .billing-item-deleted {
    font-style: italic;
    color: darkred;
  }

  .sub {
    white-space: nowrap;
    overflow: hidden;
    color: $gray17;
  }

  .billing-item-toggle {
    width: 8px;
  }

  .billing-item-date {
    width: 100px;
  }

  .billing-category,
  .billing-item-type {
    width: 160px;
    text-overflow: ellipsis;
  }

  .billing-item-deleted-by,
  .billing-item-created-by {
    width: 100px;
  }

  .billing-category,
  .billing-item-type {
    width: 160px;
    text-overflow: ellipsis;
  }

  .billing-item-amount,
  .billing-item-balance {
    text-align: right;
    width: 120px;
  }

  .billing-item-actions {
    width: 50px;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;

    button {
      margin-right: 5px;
    }
  }

  .billing-item-toggle {
    width: 24px;
  }

  tbody:hover {
    background-color: $gray5;
  }

  .billing-item-effective-date {
    border-top: 1px dashed $gray11;
  }

  td {
    border-top: none;
    padding: 0 8px;
  }

  tr:first-child td {
    padding-top: 8px;
  }

  tr:last-child td {
    padding-bottom: 8px;
  }

  tbody {
    border-top: 1px solid $gray12;
  }

  th.sortable {
    a {
      color: inherit;
    }
    svg {
      margin-left: 2px;
    }
  }
}
