.billing-account-access-picker-table {
  td {
    border: 1px solid;
    min-width: 12em;
    padding: 0.5rem;
  }

  .billing-account-access-toggle {
    position: relative;
    left: 40%;
    display: block;
    max-width: 50%;
  }

  .billing-account-access-persist {
    margin-left: 1em;
  }

  .billing-account-access-delete {
    border: 1px solid;
    margin-left: 3em;
  }

  .billing-account-access-header {
    font-weight: bold;
  }

  border: 1px solid;
  margin-bottom: 10pt;
}
