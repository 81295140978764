@import '../../../../constants/variables';

.billing-account-label{
  padding: 0 0 0 8px;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 5px;
  max-width: 100%;
}

.billing-account-input{
  margin: 10px 0 0 15px;
  padding: 4px 0 4px 0;
  border-radius: 4px;
  outline: none;
  border: 1px solid $gray12;
  width: 16%;
  max-width: 25%;
}

.billing-account-info-icon{
display: inline-block;

position: relative;
bottom: 1px;
}

.billing-title{
  padding: 20px 0 0 0;
};

.billing-account-payment-section{
  margin: 0 0 6px 0;
}
