@import '../../../constants/variables';

.revenue-report-list {
  .revenue-report-billed-total-col {
    border-right-width: 1px;
  }

  .revenue-report-recognized-col {
    border-left-width: 1px;
  }

  tr {
    border: none;

    td {
      border-bottom: none;
    }
  }

  tr:last-of-type {
    td {
      border-bottom: 1px solid;
    }
  }

  th {
    border-top: 1px solid black;
  }

  .super-header {
    th {
      border-top: none;
    }
  }

  tr.revenue-recognition-totals-row {
    td {
      border-top: 1px solid black;
    }

    .revenue-report-billed-category-col, .revenue-report-billed-total-col {
      font-weight: bold;
    }
  }

  tr.revenue-recognition-category-row:not(:last-of-type) {
    td {
      border-bottom: none;
    }
  }
}
