@import '../../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-track-silo {
  float: right;
  text-align: right;
  text-transform: none;
  line-height: 36px;

  select {
    margin-left: 4px;
  }
}

.review-cover-sheet-fields,
.review-cover-sheet-items {
  padding-bottom: 30px;
}

.review-cover-sheet-fields-section {
  padding-left: 0;
}

.review-cover-sheet-readers {
  margin: 15px 0;
}

.review-cover-sheet-readers li {
  line-height: 19px;
  font-weight: 500;
  font-size: 19px;
  padding: 0 0 5px 0;
  margin: 0 20px 0 0;
}

.review-cover-sheet-readers li.active a {
  font-weight: bold;
}

.review-cover-sheet-readers a,
.review-cover-sheet-readers a:hover {
  text-decoration: none;
}

.review-cover-sheet-field-name,
.review-cover-sheet-item-name,
.review-cover-sheet-rating-name {
  color: $gray1;
  text-align: right;
}

.review-cover-sheet-field-value,
.review-cover-sheet-comment-value,
.review-cover-sheet-rating-value,
.review-cover-sheet-rating-value button
{
  font-weight: bold;
}

.review-cover-sheet-item,
.review-cover-sheet-rating {
  margin-top: 9px;
}

.review-cover-sheet-item-list,
.review-cover-sheet-rating-list {
  margin-top: 18px;
}

.review-cover-sheet-field-value {
  white-space:pre-wrap;
  margin-left: 1.05263%; /* attempt to get the column to line up with the review comments and ratings */
}
.review-cover-sheet-item-comment-empty {
  font-style: italic;
}

.review-cover-sheet-rating-value a:after,
.review-cover-sheet-rating-value button:after,
{
  font-family: FontAwesome;
  font-weight: normal;
  content: ' \f040';
}
