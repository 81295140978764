@import '../../../../../stylesheets/shared/helpers/bootstrap_variables';

.review-field-display_name {
  a {
    color: $gray77;

    &:hover {
      text-decoration: underline;
      color: $gray77;
    }
  }
}

.review-field-_review_comments.comments {
  cursor: pointer;
  user-select: none;
}

.review-field-_review_comments__button {
  padding: 0;
  color: inherit;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    box-shadow: none;
  }
}

.comments-show .review-field-_review_comments .fa-chevron-circle-right {
  transform: rotate(90deg);
}

.candidates-in-review-contact-list__navigation {
  display: flex;
  justify-content: space-between;
}

table.candidates-in-review-contact-list__ContactList {
  margin-top: 12px;
}

.candidates-in-review-contact-list__ContactList th:not(:first-of-type) {
  min-width: 73px;
  white-space: nowrap;
}

.candidates-in-review-contact-list__ContactList td {
  line-height: 13px;
  vertical-align: middle;
}

.candidates-in-review-contact-list__no-contacts {
  margin-top: 25px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  background-color: $gray75;
}

.candidates-in-review-contact-list {
  .contact-filters-react {
    margin: 10px 0;
  }
}
